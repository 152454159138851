import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders';
import { GLTFFileLoader, GLTFLoaderAnimationStartMode } from '@babylonjs/loaders';

export class SubsceneLoader {
    private engine: BABYLON.Engine;
    private scene: BABYLON.Scene;

    constructor(    
        engine: BABYLON.Engine,
        scene: BABYLON.Scene,
    ) { 
        this.engine = engine;
        this.scene = scene;
        BABYLON.SceneLoader.OnPluginActivatedObservable.add(function (loader) {
            if (loader.name === 'gltf' && loader instanceof GLTFFileLoader) {
                loader.animationStartMode = GLTFLoaderAnimationStartMode.NONE;
            }
        });
    }

    async loadSubscene(url: string, enabled = true) {
        let host = '';
        if (url.startsWith('http://') || url.startsWith('https://')) {
            host = url;
        } else if (url.startsWith('/')) {
            host = url;
        } else {
            host = '/' + url;
        }
        const subscene = await BABYLON.SceneLoader.LoadAssetContainerAsync(
            host,
            '',
            this.scene,
            undefined,
            '.glb'
          );
        subscene.animationGroups.forEach(ag => ag.loopAnimation = false);
    
        const sceneNode = subscene.createRootMesh();
        sceneNode.metadata = {
            animations: subscene.animationGroups,
            morphs: subscene.morphTargetManagers,
        };
        sceneNode.setEnabled(enabled);
        subscene.addAllToScene();
        return sceneNode;
    }
}