import * as BABYLON from '@babylonjs/core';

export class OpaqueInsidePluginMaterial extends BABYLON.MaterialPluginBase {
  private _uniformBoundings = new BABYLON.Vector3(2, 2, 2.5);
  private _isEnabled = false;

  get isEnabled() {
    return this._isEnabled;
  }

  set isEnabled(enabled) {
    if (this._isEnabled === enabled) {
      return;
    }
    this._isEnabled = enabled;
    this.markAllDefinesAsDirty();
    this._enable(this._isEnabled);
  }

  set uniformBoundings(boundings: BABYLON.Vector3) {
    if (this._uniformBoundings === boundings) {
      return;
    }
    this._uniformBoundings = boundings;
  }

  constructor(material: BABYLON.Material) {
    super(material, "OpaqueInside", 200, { OpaqueInside: false });
  }

  prepareDefines(defines: {[key: string]: boolean}, scene: BABYLON.Scene, mesh: BABYLON.Mesh) {
    defines['OpaqueInside'] = this._isEnabled;
  }

  getUniforms() {
    return {
      ubo: [{ name: "opaqueBoundings", size: 3, type: "vec3" }],
      fragment: `#ifdef OpaqueInside
                    uniform vec3 opaqueBoundings;
                #endif`,
    };
  }

  bindForSubMesh(uniformBuffer: BABYLON.UniformBuffer, scene: BABYLON.Scene, engine: BABYLON.Engine, subMesh: BABYLON.SubMesh) {
    if (this._isEnabled) {
      uniformBuffer.updateVector3("opaqueBoundings", this._uniformBoundings);
    }
  }

  getClassName() {
    return "OpaqueInsidePluginMaterial";
  }

  getCustomCode(shaderType: "vertex" | "fragment") {
    return shaderType === "vertex"
      ? 
      {
        CUSTOM_VERTEX_BEGIN: `
              #ifdef OpaqueInside
                out float distance;
              #endif
        `,
        CUSTOM_VERTEX_MAIN_END: `
              #ifdef OpaqueInside
                distance = gl_Position.z;
              #endif
        `,
      }
      : 
      {
        CUSTOM_FRAGMENT_BEGIN: `
              #ifdef OpaqueInside
                in mediump float distance;
              #endif
        `,
        CUSTOM_FRAGMENT_MAIN_END: `
              #ifdef OpaqueInside
                glFragColor.rgb *= max(0.0, min(1.0, 0.0125 + 1.0 / (distance * 0.75)));
                glFragColor.a *= float(abs(vPositionW.x) < opaqueBoundings.x && abs(vPositionW.y) < opaqueBoundings.y && abs(vPositionW.z) < opaqueBoundings.z);
                if (glFragColor.a < 0.05) discard;
              #endif
          `,
      };
  }
}