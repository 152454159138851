import { Header } from "components/Header/Header";
import { Team } from "components/Team/Team";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("corsus-app"));

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter >
);
