import React, { useCallback, useContext } from "react";
import { observer } from "mobx-react";
import { CanvasContext, CanvasAppContext } from "service/CanvasContext";
import { SceneService } from "service/SceneService";

/**
 * Component for canvas element rendering and {@link SceneService} in context initialization.
 */
const SceneCanvas: React.FC = () => {

    const appContext = useContext<CanvasContext>(CanvasAppContext);
    const canvasRef = useCallback((canvas: HTMLCanvasElement) => {
        appContext.sceneService = new SceneService(canvas);
    }, []);

    return (
        <canvas
            ref={canvasRef}
            height={500}
            width={500}
            style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0
            }}
        />
    );
}

export default observer(SceneCanvas);
