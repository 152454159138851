import { Accordion, AccordionItem } from "components/Accardion/Accordion";
import { ALIGNMENT, InfoTabs } from "components/shared/ListOfInfo/InfoTabs";
import { Tab } from "components/shared/ListOfInfo/Tab";
import { Navigation } from "components/shared/Navigation/Navigation";
import { Pages } from "constants/slide-names";
import React, { useContext, useState, useEffect } from "react";
import { GlobalAppContext } from "service/GlobalContext";
import "./Tech.less"


const pages = [Pages.Strategies, Pages.Technology, Pages.Last];

export const Tech: React.FC = () => {
    const [position, setPosition] = useState(Pages.Strategies)
    const [previousPosition, setPeviousPosition] = useState(Pages.Strategies)
    const { isAssetsLoaded, isSceneLoaded, isContinueClicked, soundService } = useContext(GlobalAppContext)

    const onTabChange = (tab: Pages) => {
        setPeviousPosition(position);
        setPosition(tab);
    }

    const isNextTab = (tab: Pages) => {
        return pages.indexOf(tab) >= pages.indexOf(position)
    }

    const isPrevTab = (tab: Pages) => {
        return pages.indexOf(tab) >= pages.indexOf(previousPosition)
    }

    useEffect(() => {
        document.body.classList.add('tech');
        return () => {
            document.body.classList.remove('tech');
        }
    }, [])

    useEffect(() => {
        if (previousPosition === Pages.Last) {
            soundService.introAudio.play()
        }
    }, [position])

    return isAssetsLoaded && isSceneLoaded && isContinueClicked && (
        <>
            <InfoTabs position={position} prevPosition={previousPosition} onTabChange={onTabChange} pages={pages} alignment={ALIGNMENT.RIGHT}>
                <Tab position={position} tab={Pages.Strategies} className="strategies" checkIsNextTab={isNextTab} checkIsPrevTab={isPrevTab}>
                    <h1 className="title headling">CORVUS RESEARCH IO <span>001</span></h1>
                    <Accordion className="quotes-top quotes-bottom">
                        {({ openedTab, setOpenedTab }: any) =>
                            <>
                                <AccordionItem
                                    active={openedTab === 1 || !openedTab}
                                    onToggle={() => setOpenedTab(1)}
                                    header="An Automated Machine"
                                    message="IO is our automated machine for the end-to-end development of alternative data sources and quantitative strategies. A machine that we’re building, not selling!"
                                />
                                <AccordionItem
                                    active={openedTab === 2}
                                    onToggle={() => setOpenedTab(2)}
                                    header="Alternative Data Collection"
                                    message="
                                    Our team includes experts on the mechanics and nuances of some of the largest media platforms.
                                    We take a different perspective on social analytics, collecting data sources to generate indicators within equities and cryptocurrencies.
                                    "
                                />
                                <AccordionItem
                                    active={openedTab === 3}
                                    onToggle={() => setOpenedTab(3)}
                                    header="Quantitative Research"
                                    message="We are automating some of the most laborious and time-intensive parts of alpha discovery, allowing us to find predictive signals faster without risk of overfitting."
                                />
                            </>
                        }
                    </Accordion>
                </Tab>
                <Tab position={position} tab={Pages.Technology} className="technology" checkIsNextTab={isNextTab} checkIsPrevTab={isPrevTab}>
                    <h1 className="title headling">Machine Learning <span>002</span></h1>
                    <p className="paragraph quotes-top">
                        Machine Learning approaches are at the core of our strategy development and research.
                    </p>
                    <p className="paragraph">
                        We use, develop, and adapt a variety of supervised and unsupervised learning algorithms to extract meaningful predictive signals, as well as support the idea generation process.
                    </p>
                    <p className="paragraph quotes-bottom">
                        We see machine learning as a tool for pattern discovery; extracting high-dimensional asset structures, and then interpreting these in an explainable manner.
                    </p>
                </Tab>
                <Tab position={position} tab={Pages.Last} checkIsNextTab={isNextTab} checkIsPrevTab={isPrevTab} className="last">
                </Tab>
            </InfoTabs>
            <Navigation className={`tech__navigation ${position === Pages.Last ? 'center' : ''}`} />
        </>
    )
};

