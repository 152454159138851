import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { CanvasAppContext } from "service/CanvasContext";
import { GlobalAppContext } from "service/GlobalContext";

export const useNavigateByURL = (): (e: React.MouseEvent<HTMLAnchorElement>) => void => {
    const { sceneService } = useContext(CanvasAppContext);
    const { soundService: { clickNavigation }, setSceneUnload } = useContext(GlobalAppContext);
    const navigate = useNavigate()

    const navigateByHref = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        if (e.currentTarget.href === window.location.href) {
            e.preventDefault();
            return
        }

        if (!sceneService.isStageReady && e.currentTarget.href) {
            e.preventDefault();
            const checkLoop = (href: string) => {
                if (!sceneService.isStageReady) {
                    const timeout = setTimeout(() => {
                        clearTimeout(timeout);
                        checkLoop(href)
                    }, 200)
                } else {
                    const url = new URL(href);
                    clickNavigation.play()
                    setSceneUnload();
                    navigate(url.pathname)
                }
            }

            checkLoop(e.currentTarget.href)
            return
        }
        clickNavigation.play()
        setSceneUnload();
    }

    return navigateByHref;
}
