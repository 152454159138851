export const teamMembers = [
    {
        src: 'assets/images/Stanley_Speel.jpeg',
        name: 'Stanley Speel',
        position: 'Co-founder & CEO',
        social: {
            linkedin: 'https://www.linkedin.com/in/stanley-speel-7a8157106/',
            twitter: '/'
        }
    },
    {
        src: "assets/images/Aaron_O_Brien.jpeg",
        name: 'Aaron O’Brien',
        position: 'Quantitative Researcher',
        social: {
            linkedin: 'https://www.linkedin.com/in/aaron-obrien/',
            twitter: '/'
        }
    },
    {
        src: 'assets/images/Jack_Munsaka.jpeg',
        name: 'Jack Munsaka',
        position: 'Software Engineer',
        social: {
            linkedin: 'https://www.linkedin.com/in/jack-munanga-munsaka/',
            twitter: '/'
        }
    },
    {
        src: 'assets/images/Konrad_Edwards.png',
        name: 'Konrad Edwards',
        position: 'Quantitative Developer',
        social: {
            linkedin: 'https://www.linkedin.com/in/konrad-edwards-86a8a8144/',
            twitter: '/'
        }
    },
    {
        src: 'assets/images/Tigran_Atoyan.jpeg',
        name: 'Tigran Atoyan',
        position: 'Research Advisor - Institutional Alpha',
        social: {
            linkedin: 'https://www.linkedin.com/in/tigran-atoyan/',
            twitter: '/'
        }
    },
    {
        src: 'assets/images/Sam_Hillman.jpeg',
        name: 'Sam Hillman',
        position: 'Co-founder & COO',
        social: {
            linkedin: '/',
            twitter: '/'
        }
    }
    ]
