import {v4 as uuid} from 'uuid';

/**
 * Service side events enum.
 */
export enum SceneEvent {
    ASSETS_LOADED = "ASSETS_LOADED",
    COMPLETED = "COMPLETED",
    FLICKER = "FLICKER",
    FLICKER_OFF = "FLICKER_OFF",
    TERRAINS_LOADED = "TERRAINS_LOADED",
}

/**
 * Consumer on event. Id using for removing from consumers list by ID ability.
 */
export class SceneEventConsumer {

    id: string;

    callback: SceneEventConsumerCallback;

    constructor(callback: SceneEventConsumerCallback) {
        this.id = uuid();
        this.callback = callback;
    }
}

/**
 * Consumer callback type.
 */
export type SceneEventConsumerCallback = (value?: any) => void;

export class SceneEventConsumers {
    consumersList: SceneEventConsumer[] = [];
}