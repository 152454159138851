import gsap from "gsap";
import React, { useLayoutEffect, useRef, useState, Children } from "react";

export const Tab: React.FC<any> = ({ position, tab, checkIsNextTab, checkIsPrevTab, children, className = "" }) => {
    const tabRef = useRef();
    const [visible, setVisible] = useState(false)

    useLayoutEffect(() => {
        if (position === tab) {
            setVisible(true);
        } else if (tabRef.current) {
            Children.forEach(children, (_, index) => {
                gsap.fromTo(`.tab > :nth-child(${index + 1})`,
                    {
                        y: 0,
                        opacity: 1,
                        transition: 0
                    },
                    {
                        y: checkIsNextTab(tab) ? 100 : -100,
                        opacity: 0,
                        duration: .7,
                        ease: "strong.inOut",
                        delay: index * .1,
                    }
                ).then(() => {
                    setVisible(false)
                })
            })
        }

    }, [position]);

    useLayoutEffect(() => {
        if (visible) {
            gsap.timeline({ defaults: { duration: 0 } })
                .from(`.${className}`,
                    {
                        opacity: 0
                    }
                ).to(`.${className}`,
                    {
                        opacity: 1
                    }
                ).then(() => {
                    Children.forEach(children, (_, index) => {
                        gsap.fromTo(`.tab > :nth-child(${index + 1})`,
                            {
                                y: checkIsPrevTab(tab) ? 100 : -100,
                                opacity: 0,
                                transition: 0
                            },
                            {
                                y: 0,
                                duration: .7,
                                ease: "strong.inOut",
                                delay: .7 + index * .3,
                                opacity: 1,
                            }
                        )
                    })
                })
        }
    }, [visible])

    return visible && (<div className={`tab ${className}`} ref={tabRef}>
        {children}
    </div>)
}
