import React, { useContext } from "react";
import { GlobalAppContext } from "service/GlobalContext";
import "./SoundButton.less";

export const SoundButton: React.FC<{ className?: string }> = ({ className = '' }) => {
    const { toggleAppAudio, isAudioAvailable } = useContext(GlobalAppContext);
    const { soundService: { interactionEffect } } = useContext(GlobalAppContext)

    const onSoundButtonClick = () => {
        toggleAppAudio()
    }

    const onMouseEnter = () => {
        interactionEffect.play();
    }

    return (
        <div className={`${className} sound-button quotes-top quotes-bottom ${isAudioAvailable ? 'sound-button--active' : ''}`} onClick={onSoundButtonClick} onMouseEnter={onMouseEnter}>
            <span className="sound-button__volume"></span>
            <span className="sound-button__volume"></span>
            <span className="sound-button__volume"></span>
            <span className="sound-button__volume"></span>
        </div>
    )
}
