import { makeAutoObservable } from "mobx";
import { SceneService } from "service/SceneService";
import React from "react";

/**
 * Global app context.
 */
export class CanvasContext {

    sceneService: SceneService = null;

    constructor() {
        makeAutoObservable(this);
    }
}

export const CanvasAppContext: React.Context<CanvasContext> = React.createContext<CanvasContext>(null);
