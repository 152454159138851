import { Accordion, AccordionItem } from "components/Accardion/Accordion";
import { Button } from "components/shared/Button";
import { InfoTabs } from "components/shared/ListOfInfo/InfoTabs";
import { Tab } from "components/shared/ListOfInfo/Tab";
import { Navigation } from "components/shared/Navigation/Navigation";
import { ROUTES } from "constants/routes";
import { Pages } from "constants/slide-names";
import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { GlobalAppContext } from "service/GlobalContext";
import "./Main.less";

const pages = [Pages.Greeting, Pages.Approach, Pages.Core, Pages.Team, Pages.Last];

export const Main: React.FC = () => {
    const [position, setPosition] = useState(Pages.Greeting)
    const [previousPosition, setPeviousPosition] = useState(Pages.Greeting)
    const { isAssetsLoaded, isSceneLoaded, isContinueClicked, soundService } = useContext(GlobalAppContext)

    const onTabChange = (tab: Pages) => {
        setPeviousPosition(position);
        setPosition(tab);
    }

    const isNextTab = (tab: Pages) => {
        return pages.indexOf(tab) >= pages.indexOf(position)
    }

    const isPrevTab = (tab: Pages) => {
        return pages.indexOf(tab) >= pages.indexOf(previousPosition)
    }

    useEffect(() => {
        if (previousPosition === Pages.Last) {
            soundService.introAudio.play()
        }
    }, [position])

    return isAssetsLoaded && isSceneLoaded && isContinueClicked && (
        <>
            <InfoTabs position={position} prevPosition={previousPosition} onTabChange={onTabChange} pages={pages}>
                <Tab position={position} tab={Pages.Greeting} checkIsNextTab={isNextTab} checkIsPrevTab={isPrevTab} className="greering">
                    <h1 className="title headling">Alternative Alpha,<span>001</span></h1>
                    <h1 className="title">Evolved.</h1>
                    <p className="paragraph quotes-top">
                        Corvus Research is a technology company developing cutting-edge statistical models, alternative data sources, and machine learning for quantitative finance.
                    </p>
                    <p className="paragraph quotes-bottom">
                        Our mission is to build a fully automated machine for finding predictive signals in a variety of markets.
                    </p>
                </Tab>
                <Tab position={position} tab={Pages.Approach} checkIsNextTab={isNextTab} checkIsPrevTab={isPrevTab} className="approach">
                    <h1 className="title headling">About Us <span>002</span></h1>
                    <p className="paragraph quotes-top">
                        We develop mid-frequency trading strategies that demonstrate clear distinctions between their underlying alpha and associated portfolio management.
                    </p>
                    <p className="paragraph">
                        Our algorithms predict sudden asset movements by combining innovative quantitative methods with our alternative data.
                    </p>
                    <p className="paragraph quotes-bottom">
                        We then apply traditional portfolio management techniques to formulate these predictions into quantitative strategies.
                    </p>
                </Tab>
                <Tab position={position} tab={Pages.Core} checkIsNextTab={isNextTab} checkIsPrevTab={isPrevTab} className="core">
                    <h1 className="title headling">Our Research <span>003</span></h1>
                    <div className="core__benefites quotes-top">
                        <div className="paragraph">
                            <h3 className="subtitle-2">
                                Event-Driven Machine Learning
                            </h3>
                            <p>
                                Understanding transient asset movements that cannot be explained by traditional cross-sectional approaches.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h3 className="subtitle-2">
                                Dynamic Networks
                            </h3>
                            <p>
                                Modelling multidimensional relationships between assets over time, to maintain relevance in ever-changing market conditions.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h3 className="subtitle-2">
                                Mid-Frequency Modelling
                            </h3>
                            <p className="quotes-bottom">
                                We focus on event-driven and market-neutral quantitative strategies that operate over minutes, hours, and days.
                            </p>
                        </div>
                    </div>
                    <Accordion>
                        {({ openedTab, setOpenedTab }: any) =>
                            <>
                                <AccordionItem
                                    active={openedTab === 1 || !openedTab}
                                    onToggle={() => setOpenedTab(1)}
                                    header="Event-Driven Machine Learning"
                                    message="Understanding transient asset movements that cannot be explained by traditional cross-sectional approaches."
                                />
                                <AccordionItem
                                    active={openedTab === 2}
                                    onToggle={() => setOpenedTab(2)}
                                    header="Dynamic Networks"
                                    message="Modelling multidimensional relationships between assets over time, to maintain relevance in ever-changing market conditions."
                                />
                                <AccordionItem
                                    active={openedTab === 3}
                                    onToggle={() => setOpenedTab(3)}
                                    header="Mid-Frequency Modelling"
                                    message="We focus on event-driven and market-neutral quantitative strategies that operate over minutes, hours, and days."
                                />
                            </>
                        }
                    </Accordion>
                </Tab>
                <Tab position={position} tab={Pages.Team} checkIsNextTab={isNextTab} checkIsPrevTab={isPrevTab} className="team">
                    <h1 className="title headling">Team <span>004</span></h1>
                    <p className="paragraph quotes-top">
                        Our people are some of the brightest minds spanning machine learning, software engineering, and quantitative trading.
                    </p>
                    <p className="paragraph">
                        We encourage an entrepreneurial and open culture where everyone enjoys working together on some of the most intellectually challenging problems.
                    </p>
                    <div className="quotes-bottom button__container">
                        <NavLink to={ROUTES.Team}>
                            <Button>meet our team</Button>
                        </NavLink>
                    </div>
                </Tab>
                <Tab position={position} tab={Pages.Last} checkIsNextTab={isNextTab} checkIsPrevTab={isPrevTab} className="last">
                </Tab>
            </InfoTabs>
            <Navigation className={`main__navigation ${position === Pages.Last ? 'center' : 'hidden'}`} />
        </>
    )
};

