import { Button } from "components/shared/Button";
import { Navigation } from "components/shared/Navigation/Navigation";
import React, { useContext, useLayoutEffect } from "react";
import { GlobalAppContext } from "service/GlobalContext";
import gsap from "gsap";
import "./Contact.less";

export const Contact: React.FC = () => {
    const { soundService: { interactionEffect } } = useContext(GlobalAppContext);

    const onMouseEnter = () => {
        interactionEffect.play();
    }

    const getAnimationConfigs = (delayRatio: number = 0): [any, any] => [
        {
            y: -100,
            opacity: 0,
        },
        {
            y: 0,
            opacity: 1,
            ease: "strong.inOut",
            delay: .7 + 0.2 * delayRatio,
            duration: .7,
        }
    ]

    useLayoutEffect(() => {
        gsap.fromTo('.contact .title.headling', ...getAnimationConfigs())
        gsap.fromTo('.contact .contact__company-name', ...getAnimationConfigs(1))
        gsap.fromTo('.contact .contact__address-info', ...getAnimationConfigs(1))

        gsap.fromTo('.contact .contact__mail-container', ...getAnimationConfigs(2))

        gsap.fromTo('.contact .contact__form .title-3', ...getAnimationConfigs(1))
        gsap.fromTo('.contact .form', ...getAnimationConfigs(2))



    }, [])

    return (
        <section className="contact container">
            <div className="contact__container">
                <div className="contact__info">
                    <h1 className="title headling">
                        Contact <span>002</span>
                    </h1>
                    <span className="contact__company-name paragraph">
                        Corvus Research
                    </span>
                    <div className="contact__address-info">
                        <div className="contact__address paragraph">
                            <span>8 Hermitage Street</span>
                            <span>London</span>
                            <span>W2 1BE</span>
                        </div>
                        <div className="contact__mail-container">
                            <a href="mailto::info@corvusresearch.com" onMouseEnter={onMouseEnter} className="contact__mail">info@corvusresearch.com</a>
                            <a href="mailto::careers@corvusresearch.com" onMouseEnter={onMouseEnter} className="contact__mail">careers@corvusresearch.com</a>
                        </div>
                    </div>
                </div>
                <div className="contact__form">
                    <h2 className="title-3">
                        Drop us a line
                    </h2>
                    <form
                        action="https://formspree.io/f/mvoygozk"
                        method="POST"
                        className="form"
                    >
                        <div className="form__row">
                            <div className="form__input input-label">
                                <input id="name" type="text" name="username" onFocus={onMouseEnter} placeholder=" " required />
                                <label htmlFor="name" className="form__label input-label">name</label>
                            </div>
                            <div className="form__input input-label">
                                <input id="email" type="email" placeholder=" " onFocus={onMouseEnter} name="email" required />
                                <label htmlFor="email" className="form__label input-label">email</label>
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__input input-label">
                                <input id="details" type="text" placeholder=" " onFocus={onMouseEnter} name="details" required />
                                <label htmlFor="details" className="form__label input-label">details</label>
                            </div>
                        </div>
                        <Button className="form__submit">SUBMIT</Button>
                    </form>
                </div >
            </div >
            <Navigation className="contact__navigation" />
        </section >
    );
}
