import * as BABYLON from "@babylonjs/core";
import { SceneEvent, SceneEventConsumer, SceneEventConsumerCallback, SceneEventConsumers } from "service/SceneEvents";
import { SubsceneLoader } from "./SubsceneLoader";
import { OpaqueInsidePluginMaterial, FlickeringPluginMaterial, ObscureInCenterPluginMaterial } from "./shaderPlugins";
import { GridMaterial } from "@babylonjs/materials";

import { SceneStage, StagesManager } from './StagesManager';

import cube from "../../assets/cube_anim.glb";
import cube_a from "../../assets/cube_a.glb";
import cube_b from "../../assets/cube_b.glb";
import cube_c from "../../assets/cube_c.glb";
import cube_d from "../../assets/cube_d.glb";
import gradient_lines from "../../assets/lines1.glb";
import lights_on from "../../assets/lights_on.glb";
import cube_planes from "../../assets/cube_planes.glb";
import cube_sparks from "../../assets/cube_sparks.glb";
import ground from "../../assets/ground.glb";
import fractal from "../../assets/fractal.glb";
import copula from "../../assets/copula.png";
import terrainSky from "../../assets/skyTerrain.glb";
import radial from "../../assets/radial.png";
import reflectivity from "../../assets/reflectivity.jpeg";
import facesTexture from "../../assets/facesTexture.png"
import { ShowAroundPluginMaterial } from "./shaderPlugins/ShowAroundShaderPlugin";

type Subscenes = {
    logo: BABYLON.Mesh;
    fractal: BABYLON.Mesh;
    graph: BABYLON.Mesh;
    dna: BABYLON.Mesh;
    copula: BABYLON.Mesh;
    terrain: BABYLON.Mesh;
}

type Environment = {
    isLightOn: boolean;
    glowingCube: BABYLON.Mesh;
    facesCube: BABYLON.Mesh;
    cubeA: BABYLON.Mesh;
    cubeB: BABYLON.Mesh;
    cubeC: BABYLON.Mesh;
    cubeD: BABYLON.Mesh;
    cubePlanes: BABYLON.Mesh;
    cubeSparks: BABYLON.Mesh;
    gradientLines: BABYLON.Mesh;
    ground: BABYLON.Mesh;
    terrainCircles: BABYLON.Mesh;
    terrainGround: BABYLON.Mesh;
    terrainSky: BABYLON.Mesh;
    terrainSpheres: BABYLON.TransformNode;
    lights_on: BABYLON.Mesh;
}
let LAST_GENERATION = Date.now();

export { SceneStage as SceneStages };

export enum SceneMode {
    VERTICAL = 1,
    VERTICAL_ALT,
    HORIZONTAL,
    QUAD,
}

export class SceneService {
    private canvas: HTMLCanvasElement;
    private lastPointer = {
        x: 0,
        y: 0,
    };
    private engine: BABYLON.Engine;
    private scene: BABYLON.Scene;
    private root: BABYLON.TransformNode;
    private subscenes: Subscenes = {
        logo: undefined,
        copula: undefined,
        fractal: undefined,
        graph: undefined,
        dna: undefined,
        terrain: undefined,
    };
    private environment: Environment = {
        isLightOn: false,
        glowingCube: undefined,
        facesCube: undefined,
        cubeA: undefined,
        cubeB: undefined,
        cubeC: undefined,
        cubeD: undefined,
        mountain: undefined,
        ground: undefined,
        terrainCircles: undefined,
        terrainGround: undefined,
        terrainSky: undefined,
        terrainSpheres: undefined,
        cubePlanes: undefined,
        cubeSparks: undefined,
        gradientLines: undefined,
        lights_on: undefined,
        lightMountain3: undefined,
    };

    private boundingPlugins: any[] = [];

    private glow: BABYLON.GlowLayer;

    private camera: BABYLON.ArcRotateCamera;
    private needsOffset = true;
    private light: BABYLON.PointLight;
    private loader: SubsceneLoader;
    stagesManager: StagesManager;
    private isReady = false;
    private skipLightup = false;
    private needLines = true;
    private eventsConsumers: Map<SceneEvent, SceneEventConsumers> = new Map<SceneEvent, SceneEventConsumers>();

    public currentStage = SceneStage.LIGHTS_OFF;
    public isStageReady = true;

    private picked = false;
    private flicker?: BABYLON.AnimationGroup = undefined;

    private mode: SceneMode;

    constructor(canvas: HTMLCanvasElement, mode = SceneMode.HORIZONTAL, skipLightup = false) {
        this.skipLightup = skipLightup;
        this.canvas = canvas;
        this.initialize();
        this.loader = new SubsceneLoader(this.engine, this.scene);
        this.mode = mode;

        this.root = new BABYLON.TransformNode("root", this.scene);
        this.loadTerrains().then(() => {
            const consumers = this.eventsConsumers.get(SceneEvent.TERRAINS_LOADED);
            consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.TERRAINS_LOADED }));
            this.startLoop();

            this.loadCube().then(() => {
                this.setupSubscenes().then(() => {
                    this.loadAssets().then(() => {
                        const consumers = this.eventsConsumers.get(SceneEvent.ASSETS_LOADED);
                        consumers && consumers.consumersList.forEach(c => c.callback({
                            event: SceneEvent.ASSETS_LOADED,
                            percents: 100
                        }));
                        this.isReady = true;
                    });
                });
            });
        });

    }

    public offsetCamera(x: number, y: number) {
        this.environment.cubeSparks && this.environment.cubeSparks.getChildMeshes().forEach(m => {
            if (m.material && this.picked) {
                m.material.pluginManager.getPlugin("ShowAround")._uniformPointer.x = x;
                m.material.pluginManager.getPlugin("ShowAround")._uniformPointer.y = this.canvas.height - y;
            }
            else if (m.material && !this.picked) {
                m.material.pluginManager.getPlugin("ShowAround")._uniformPointer.x = 0;
                m.material.pluginManager.getPlugin("ShowAround")._uniformPointer.y = 0;
            }
        });
        // this.environment.gradientLines && this.environment.gradientLines.getChildMeshes().forEach(m => {
        //     if (m.material && this.picked) {
        //         m.material.pluginManager.getPlugin("ShowAround")._uniformPointer.x = x;
        //         m.material.pluginManager.getPlugin("ShowAround")._uniformPointer.y = this.canvas.height - y;
        //     } else if (m.material && !this.picked) {
        //         m.material.pluginManager.getPlugin("ShowAround")._uniformPointer.x = 0;
        //         m.material.pluginManager.getPlugin("ShowAround")._uniformPointer.y = 0;
        //     }
        // });
        if (this.needsOffset && this.isReady && this.isStageReady && this.currentStage !== SceneStage.LIGHTS_OFF) {
            const picks = this.scene.multiPick(x, y, m => m.id == 'hitbox');
            let picked = false;
            picks.forEach(pick => {
                if (pick.hit)
                    picked = true;
            });
            if (picked !== this.picked) {
                this.picked = picked;
                if (
                    this.picked &&
                    this.isStageReady &&
                    this.currentStage !== SceneStage.LIGHTS_ON &&
                    this.currentStage !== SceneStage.MEETUS
                ) {
                    const consumers = this.eventsConsumers.get(SceneEvent.FLICKER);
                    consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.FLICKER }));
                    const list = [this.environment.cubeB, this.environment.cubeC, this.environment.cubeD];
                    const index = Math.floor(Math.random() * 2.95);
                    this.flickerOnce(list, index);
                    (this.environment.cubeSparks.metadata.animations as BABYLON.AnimationGroup[]).forEach(ag => ag.start(true));
                    this.environment.glowingCube.metadata.levitation.stop();
                    this.environment.cubeSparks && this.environment.cubeSparks.getChildMeshes().forEach(m => {
                        this.glow.removeExcludedMesh(m);
                    });
                } else {
                    this.stopFlicker();
                    (this.environment.cubeSparks.metadata.animations as BABYLON.AnimationGroup[]).forEach(ag => ag.stop());
                    this.environment.glowingCube.metadata.levitation.start(true);
                    this.environment.cubeSparks && this.environment.cubeSparks.getChildMeshes().forEach(m => {
                        this.glow.addExcludedMesh(m);
                    });
                }
            }
            this.stagesManager.offsetCamera((this.lastPointer.x - x), (this.lastPointer.y - y));
        }
        this.lastPointer = { x: x, y: y };
    }

    private flickerOnce(list: BABYLON.Mesh[], index: number) {
        if (!this.picked) return;
        list[index].setEnabled(true);
        (list[index].metadata.animations as BABYLON.AnimationGroup[]).forEach(ag => {
            ag.onAnimationGroupLoopObservable.addOnce(ag => {
                list[index].setEnabled(false);
                ag.stop();
                ag.reset();
                this.flickerOnce(list, (index + 1) % 3);
            });
            this.flicker = ag;
            this.flicker.start(true, 0.75);
        });
    }

    public setMode(mode?: SceneMode) {
        if (!this.isReady) {
            return;
        }
        if (!mode) {
            if (this.canvas.width >= 1440) {
                mode = SceneMode.HORIZONTAL;
            }
            if (this.canvas.width >= 1024 && this.canvas.width < 1440) {
                mode = SceneMode.QUAD;
            }
            if (this.canvas.width < 1024) {
                mode = SceneMode.VERTICAL;
            }
        }
        if (mode && this.mode !== mode) {
            this.mode = mode;
            if (this.mode === SceneMode.HORIZONTAL) {
                this.environment.glowingCube.scaling = new BABYLON.Vector3(1, 1, 1);
                this.subscenes.graph.scaling = new BABYLON.Vector3(1.75, 1.75, 1.75);

                const boundings = {
                    max: new BABYLON.Vector3(1, 1, 1),
                    min: new BABYLON.Vector3(-1, -1, -1),
                };
                this.boundingPlugins.forEach(plugin => {
                    plugin.uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
                });
            }
            if (this.mode === SceneMode.QUAD) {
                this.environment.glowingCube.scaling = new BABYLON.Vector3(1, 1, 1);
                this.subscenes.graph.scaling = new BABYLON.Vector3(1.75, 1.75, 1.75);

                const boundings = {
                    max: new BABYLON.Vector3(1, 1, 1),
                    min: new BABYLON.Vector3(-1, -1, -1),
                };
                this.boundingPlugins.forEach(plugin => {
                    plugin.uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
                });
            }
            if (this.mode === SceneMode.VERTICAL) {
                this.environment.glowingCube.scaling = new BABYLON.Vector3(0.75, 0.75, 0.75);
                this.subscenes.graph.scaling = new BABYLON.Vector3(1.5, 1.5, 1.5);

                const boundings = {
                    max: new BABYLON.Vector3(0.75, 1, 0.75),
                    min: new BABYLON.Vector3(-0.75, -1, -0.75),
                };
                this.boundingPlugins.forEach(plugin => {
                    plugin.uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
                });
            }
            if (this.mode === SceneMode.VERTICAL_ALT) {
                this.environment.glowingCube.scaling = new BABYLON.Vector3(0.75, 0.75, 0.75);
                this.subscenes.graph.scaling = new BABYLON.Vector3(1.5, 1.5, 1.5);

                const boundings = {
                    max: new BABYLON.Vector3(0.75, 1, 0.75),
                    min: new BABYLON.Vector3(-0.75, -1, -0.75),
                };
                this.boundingPlugins.forEach(plugin => {
                    plugin.uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
                });
            }

            this.currentStage !== SceneStage.LIGHTS_OFF && this.setStage(this.currentStage);
        }
    }

    private async loadCube() {
        let result = await this.loader.loadSubscene(cube);
        this.environment.glowingCube = result;
        this.environment.glowingCube.setParent(this.root);
        this.environment.glowingCube.position.y -= 1;
        this.environment.glowingCube.getChildMeshes().forEach(m => {
            if (m.material) {
                // m.material.emissiveColor = new BABYLON.Color3(1, 0, 1);
                // m.material.pluginManager.getPlugin("ShowAround").isEnabled = true;
                this.glow.referenceMeshToUseItsOwnMaterial(m);
            }
        });
        const levitation = new BABYLON.AnimationGroup('levitation', this.scene);
        const animation = new BABYLON.Animation(
            'levitation',
            'position.y',
            25,
            BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
        );
        animation.setKeys([
            { frame: 0, value: -1 },
            { frame: 75, value: -1.0625 },
            { frame: 150, value: -1 },
            { frame: 225, value: -0.9375 },
            { frame: 300, value: -1 }
        ]);
        levitation.addTargetedAnimation(animation, this.environment.glowingCube);
        this.environment.glowingCube.metadata.levitation = levitation;

        const size = this.environment.glowingCube.getHierarchyBoundingVectors();
        const hitbox = BABYLON.MeshBuilder.CreateBox('hitbox', {
            width: size.max.x - size.min.x,
            height: size.max.y - size.min.y,
            depth: size.max.z - size.min.z,
        }, this.scene);
        hitbox.id = 'hitbox';
        hitbox.visibility = 0;

        const consumers = this.eventsConsumers.get(SceneEvent.ASSETS_LOADED);
        consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.ASSETS_LOADED, percents: 9 }));
    }

    private async loadTerrains() {
        let result = await this.loader.loadSubscene(terrainSky);
        this.environment.terrainGround = result.clone();
        this.environment.terrainSpheres = new BABYLON.TransformNode('terrainSpheres', this.scene);
        this.environment.terrainSpheres.setParent(this.camera);
        this.environment.terrainSky = result;
        this.environment.terrainSky.position = new BABYLON.Vector3(-10, 7, 0);
        this.environment.terrainSky.scaling = new BABYLON.Vector3(3, 1, 3);
        this.environment.terrainSky.setParent(this.camera);
        const skyMaterial = new BABYLON.StandardMaterial('skyMaterial');
        skyMaterial.fillMode = BABYLON.Material.LineListDrawMode;
        skyMaterial.emissiveColor = new BABYLON.Color3(0.8, 0.8, 0.8);
        skyMaterial.fogEnabled = true;
        skyMaterial.pluginManager.getPlugin("ObscureInCenter").isEnabled = true;
        const obscure = new BABYLON.Vector4(0, 0, 0, 100);
        skyMaterial.pluginManager.getPlugin("ObscureInCenter").uniformCenter = obscure;
        const sphereMaterial = new BABYLON.StandardMaterial('SphereMaterial');
        sphereMaterial.emissiveColor = new BABYLON.Color3(0.8, 0.8, 0.8);
        sphereMaterial.fogEnabled = true;
        const sphere = BABYLON.MeshBuilder.CreateSphere('skySphere', { segments: 4, diameter: 1 }, this.scene);
        this.glow.addExcludedMesh(sphere);
        this.environment.terrainSky.getChildMeshes().forEach(m => {
            m.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
            if (m.material) {
                m.visibility = 0;
                m.material = skyMaterial;
                m.material.alpha = 0.25;
                this.glow.addExcludedMesh(m);
                const skyPositions = m.getVerticesData(BABYLON.VertexBuffer.PositionKind);
                sphere.material = sphereMaterial;
                sphere.isVisible = false;
                for (let i = 0; i < skyPositions.length; i += 3) {
                    if (Math.random() < 0.05) {
                        const instance = sphere.createInstance(sphere.name + i);
                        instance.isVisible = false;
                        instance.position.x = -3 * skyPositions[i] - 10;
                        instance.position.y = 7 + skyPositions[i + 1];
                        instance.position.z = 3 * skyPositions[i + 2];
                        const scale = Math.random() * 0.125;
                        instance.scaling = new BABYLON.Vector3(scale, scale, scale);
                        instance.setParent(this.environment.terrainSpheres);
                    }
                }
            }
        });
        this.environment.terrainGround.position = new BABYLON.Vector3(0, -17, 0);
        this.environment.terrainGround.scaling = new BABYLON.Vector3(3, 1, 3);
        this.environment.terrainGround.setParent(this.camera);

        this.environment.terrainGround.getChildMeshes().forEach(m => {
            m.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
            if (m.material) {
                m.visibility = 0;
                m.material = skyMaterial.clone('groundMaterial');
                m.material.alpha = 0.125;
                this.glow.addExcludedMesh(m);
            }
        });
    }

    private async loadAssets() {
        const consumers = this.eventsConsumers.get(SceneEvent.ASSETS_LOADED);

        const glowingMaterial = new BABYLON.StandardMaterial('glowingCube', this.scene);
        glowingMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
        glowingMaterial.cullBackFaces = false;

        let result = await this.loader.loadSubscene(cube_a);
        this.environment.cubeA = result;
        this.environment.cubeA.setParent(this.environment.glowingCube);
        this.environment.cubeA.position.y -= 1;
        this.environment.cubeA.setEnabled(false);
        this.environment.cubeA.getChildMeshes().forEach(m => {
            m.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
            if (m.material) {
                m.material = glowingMaterial;
            }
        });

        consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.ASSETS_LOADED, percents: 25 }));

        result = await this.loader.loadSubscene(cube_b);
        this.environment.cubeB = result;
        this.environment.cubeB.setParent(this.environment.glowingCube);
        this.environment.cubeB.position.y -= 1;
        this.environment.cubeB.setEnabled(false);
        this.environment.cubeB.getChildMeshes().forEach(m => {
            m.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
            if (m.material) {
                m.material = glowingMaterial;
            }
        });

        result = await this.loader.loadSubscene(cube_c);
        this.environment.cubeC = result;
        this.environment.cubeC.setParent(this.environment.glowingCube);
        this.environment.cubeC.position.y -= 1;
        this.environment.cubeC.setEnabled(false);
        this.environment.cubeC.getChildMeshes().forEach(m => {
            m.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
            if (m.material) {
                m.material = glowingMaterial;
            }
        });

        result = await this.loader.loadSubscene(cube_d);
        this.environment.cubeD = result;
        this.environment.cubeD.setParent(this.environment.glowingCube);
        this.environment.cubeD.position.y -= 1;
        this.environment.cubeD.setEnabled(false);
        this.environment.cubeD.getChildMeshes().forEach(m => {
            m.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
            if (m.material) {
                m.material = glowingMaterial;
            }
        });

        consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.ASSETS_LOADED, percents: 36 }));

        this.environment.terrainCircles = new BABYLON.Mesh('circles');
        this.environment.terrainCircles.metadata = {};
        this.environment.terrainCircles.setParent(this.camera);
        let spheres = 15;
        const backgroundSphere = BABYLON.MeshBuilder.CreateSphere('sphere', { diameter: 1.5 }, this.scene);
        this.environment.terrainCircles.metadata.sphere = backgroundSphere;
        backgroundSphere.isVisible = false;
        backgroundSphere.visibility = 0;
        backgroundSphere.material = new BABYLON.StandardMaterial('sphereMat');
        backgroundSphere.material.disableLighting = true;
        backgroundSphere.material.fogEnabled = true;
        backgroundSphere.material.emissiveColor = new BABYLON.Color3(0.125, 0.125, 0.125);
        backgroundSphere.setParent(this.environment.terrainCircles);
        while (spheres > 0) {
            const position = new BABYLON.Vector3(
                Math.random() * 50 - 25,
                Math.random() * 10 - 5,
                Math.random() * 50 - 25,
            );
            if (position.length() > 15) {
                spheres--;
                const instance = backgroundSphere.createInstance('sphere' + spheres);
                instance.position = position;
                const rescale = Math.random() * 0.25 + 0.0625;
                instance.scaling = new BABYLON.Vector3(rescale, rescale, rescale);
                instance.setParent(this.environment.terrainCircles);
            }
        }

        consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.ASSETS_LOADED, percents: 45 }));

        result = await this.loader.loadSubscene(lights_on);
        this.environment.lights_on = result;
        this.environment.lights_on.setEnabled(false);
        this.environment.lights_on.position = new BABYLON.Vector3(0, -1, 0);
        this.environment.lights_on.setParent(this.root);
        this.environment.lights_on.getChildMeshes().forEach(m => {
            m.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
            if (m.material) {
                m.rotation = new BABYLON.Vector3(-12 * Math.PI / 180, -1.5 * Math.PI / 180, 0);
                m.material.emissiveColor = new BABYLON.Color3(1, 1, 1);
                m.material.backFaceCulling = false;
                this.glow.referenceMeshToUseItsOwnMaterial(m);
            }
        });
        (this.environment.lights_on.metadata.animations as BABYLON.AnimationGroup[]).forEach(ag => ag.start(false, 1, ag.from, ag.to * 0.85));

        consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.ASSETS_LOADED, percents: 54 }));

        this.stagesManager.onStart(SceneStage.LIGHTS_ON, () => {
            this.toggleLights(true);
        });
        this.stagesManager.onEnd(SceneStage.LIGHTS_OFF, () => {
            this.toggleLights(false);
        });

        result = await this.loader.loadSubscene(gradient_lines)
        this.environment.gradientLines = result;
        this.environment.gradientLines.position = new BABYLON.Vector3(0, -1, 0);
        this.environment.gradientLines.setParent(this.environment.glowingCube);
        (this.environment.gradientLines.metadata.animations as BABYLON.AnimationGroup[]).forEach(ag => ag.start(true));
        this.environment.gradientLines.setEnabled(false);
        this.environment.gradientLines.getChildMeshes().forEach(m => {
            m.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
            if (m.material) {
                // m.material.pluginManager.getPlugin("ShowAround").isEnabled = true;
                m.material.emissiveColor = new BABYLON.Color3(1, 1, 1);
                this.glow.referenceMeshToUseItsOwnMaterial(m);
            }
        });

        consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.ASSETS_LOADED, percents: 72 }));

        result = await this.loader.loadSubscene(cube_sparks)
        this.environment.cubeSparks = result;
        this.environment.cubeSparks.position = new BABYLON.Vector3(0, -1, 0);
        this.environment.cubeSparks.setParent(this.environment.glowingCube);
        this.environment.cubeSparks.getChildMeshes().forEach(m => {
            m.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
            if (m.material) {
                m.material.transparencyMode = 4;
                m.material.alpha = 0;
                m.material.emissiveColor = new BABYLON.Color3(1, 1, 1);
                m.material.pluginManager.getPlugin("ShowAround").isAlpha = true;
                m.material.pluginManager.getPlugin("ShowAround").isEnabled = true;
                this.glow.referenceMeshToUseItsOwnMaterial(m);
                this.glow.addExcludedMesh(m);
            }
        });

        this.environment.cubeSparks.setEnabled(false);
        consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.ASSETS_LOADED, percents: 84 }));

        result = await this.loader.loadSubscene(cube_planes);
        this.environment.cubePlanes = result;
        this.environment.cubePlanes.position = new BABYLON.Vector3(0, 0.125, 0.09375);
        this.environment.cubePlanes.scaling = new BABYLON.Vector3(2.5, 2.5, 2.5);
        this.environment.cubePlanes.setParent(this.environment.glowingCube);
        this.environment.cubePlanes.getChildMeshes().forEach(m => {
            m.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
            if (m.material) {
                const pixels = new Uint8Array((m.material as BABYLON.PBRMaterial).albedoTexture._readPixelsSync().buffer);
                for (let i = 0; i < pixels.byteLength; i += 4) {
                    const color = Math.floor(pixels[i + 3]);
                    pixels[i] = color * 1;
                    pixels[i + 1] = color * 1;
                    pixels[i + 2] = color * 1;
                }
                (m.material as BABYLON.PBRMaterial).emissiveTexture = new BABYLON.RawTexture(pixels, 1080, 1080);
                m.material.backFaceCulling = false;
                (m.material.pluginManager.getPlugin("OpaqueInside") as any).isEnabled = true;
                const boundings = {
                    max: new BABYLON.Vector3(1.5, 1.5, 1.5),
                    min: new BABYLON.Vector3(-1.5, -1.5, -1.5),
                };
                (m.material.pluginManager.getPlugin("OpaqueInside") as any).uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
            }
        });
        this.environment.cubePlanes.setEnabled(false);

        consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.ASSETS_LOADED, percents: 99 }));

        result = await this.loader.loadSubscene(ground);
        this.environment.ground = result;
        this.environment.ground.scaling = new BABYLON.Vector3(1.5, 1.5, 1.5);
        this.environment.ground.setParent(this.camera);
        this.glow.addExcludedMesh(this.environment.ground);
        const mirrorTexture = new BABYLON.MirrorTexture("groundTexture", 1024, this.scene, true);
        mirrorTexture.mirrorPlane = BABYLON.Plane.FromPositionAndNormal(
            new BABYLON.Vector3(0, -1.25, 0),
            new BABYLON.Vector3(0, -1, 0)
        );
        mirrorTexture.blurKernelX = 16;
        mirrorTexture.blurKernelY = 16;
        mirrorTexture.renderList = [
            this.environment.glowingCube.getChildMeshes(),
            this.environment.cubePlanes.getChildMeshes(),
            this.subscenes.logo.getChildMeshes(),
            this.subscenes.dna.getChildMeshes(),
            this.subscenes.fractal.getChildMeshes(),
            this.subscenes.graph.getChildMeshes(),
            this.subscenes.terrain.getChildMeshes(),
            this.subscenes.copula.getChildMeshes(),
        ].flat();
        mirrorTexture.level = 1;
        this.environment.ground.getChildMeshes().forEach(m => {
            m.visibility = 0;
            if (m.material && (m.material as BABYLON.PBRMaterial).albedoTexture) {
                (m.material as BABYLON.PBRMaterial).fogEnabled = true;
                (m.material as BABYLON.PBRMaterial).useMetallnessFromMetallicTextureBlue = false;
                (m.material as BABYLON.PBRMaterial).useAlphaFromAlbedoTexture = false;
                (m.material as BABYLON.PBRMaterial).opacityTexture = new BABYLON.Texture(radial, this.scene);
                (m.material as BABYLON.PBRMaterial).opacityTexture.getAlphaFromRGB = true;
                const pool = m.clone("pool", m.parent);
                this.glow.addExcludedMesh(pool);
                pool.material = m.material.clone("poolMaterial");
                (pool.material as BABYLON.PBRMaterial).opacityTexture = new BABYLON.Texture(reflectivity, this.scene);
                (pool.material as BABYLON.PBRMaterial).opacityTexture.getAlphaFromRGB = true;
                (pool.material as BABYLON.PBRMaterial).reflectionTexture = mirrorTexture;
            }
            this.glow.addExcludedMesh(m as BABYLON.Mesh);
        });

        consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.ASSETS_LOADED, percents: 100 }));
    }

    private setupStages() {
        this.stagesManager.setMeshPositionForStage(
            SceneStage.LOGO,
            this.subscenes.logo,
            this.subscenes.logo.position,
            this.subscenes.logo.position,
            this.subscenes.logo.position
        );
        this.stagesManager.onStart(SceneStage.LOGO, () => {
            this.subscenes.logo.getChildMeshes(true).forEach(m => {
                BABYLON.Animation.CreateAndStartAnimation(m.name + 'On', m, 'visibility', 25, 100, 0, 0.95, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
            });
        });
        this.subscenes.dna.position.x += 0.5;
        this.stagesManager.setMeshPositionForStage(SceneStage.DNA,
            this.subscenes.dna,
            this.subscenes.dna.position,
            this.subscenes.dna.position,
            this.subscenes.dna.position
        );
        this.stagesManager.onStart(SceneStage.DNA, () => {
            this.subscenes.dna.getChildMeshes(true).forEach(m => {
                BABYLON.Animation.CreateAndStartAnimation(m.name + 'On', m, 'visibility', 25, 100, 0, 0.95, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
            });
        });
        this.stagesManager.setMeshPositionForStage(SceneStage.GRAPH,
            this.subscenes.graph,
            this.subscenes.graph.position,
            this.subscenes.graph.position,
            this.subscenes.graph.position
        );
        this.stagesManager.onStart(SceneStage.GRAPH, () => {
            this.subscenes.graph.getChildMeshes().forEach(m => {
                if (m.name == 'graphLinesMesh') {
                    BABYLON.Animation.CreateAndStartAnimation(m.name + 'On', m, 'visibility', 25, 100, 0, 1, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                }
                if (m.name == 'graphSphere') {
                    BABYLON.Animation.CreateAndStartAnimation(m.name + 'On', m, 'visibility', 25, 100, 0, 0.6, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                }
            });
        });
        this.stagesManager.setMeshPositionForStage(SceneStage.FRACTAL,
            this.subscenes.fractal,
            this.subscenes.fractal.position,
            this.subscenes.fractal.position,
            this.subscenes.fractal.position
        );
        this.stagesManager.onStart(SceneStage.FRACTAL, () => {
            this.subscenes.copula.scaling = new BABYLON.Vector3(0.001, 0.001, 0.001);
            BABYLON.Animation.CreateAndStartAnimation(this.subscenes.fractal.name + 'On', this.subscenes.fractal, 'visibility', 25, 100, 0, 0.95, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
            BABYLON.Animation.CreateAndStartAnimation(this.subscenes.copula.name + 'On', this.subscenes.copula, 'visibility', 25, 25, 0, 0.1, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        });
        this.stagesManager.setMeshPositionForStage(SceneStage.COPULA,
            this.subscenes.copula,
            this.subscenes.copula.position,
            this.subscenes.copula.position,
            this.subscenes.copula.position
        );
        this.stagesManager.onStart(SceneStage.COPULA, () => {
            this.subscenes.copula.scaling = new BABYLON.Vector3(0.059124913378157715, 1.41899836063385, 0.059124913378157715);
            BABYLON.Animation.CreateAndStartAnimation(this.subscenes.copula.name + 'On', this.subscenes.copula, 'visibility', 25, 100, 0, 0.95, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        });
        this.stagesManager.setMeshPositionForStage(SceneStage.TERRAIN,
            this.subscenes.terrain,
            this.subscenes.terrain.position,
            this.subscenes.terrain.position,
            this.subscenes.terrain.position
        );
        this.stagesManager.onStart(SceneStage.TERRAIN, () => {
            BABYLON.Animation.CreateAndStartAnimation(this.subscenes.terrain.name + 'On', this.subscenes.terrain, 'visibility', 25, 100, 0, 0.95, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        });

        this.stagesManager.compile();
    }

    public setStage(stage: SceneStage) {
        if (this.isStageReady) {
            this.isStageReady = false;
            if (stage === SceneStage.MEETUS) {
                this.toggleLights(true, () => {
                    this.isStageReady = true;
                    this.completeStage(stage);
                }, false);
            } else {
                if (!this.environment.isLightOn) {
                    stage !== SceneStage.LIGHTS_OFF && stage !== SceneStage.LIGHTS_ON && this.toggleLights(true, () => this.stagesManager.setStage(stage, this.mode), true, this.currentStage === SceneStage.MEETUS);
                } else {
                    this.stagesManager.setStage(stage, this.mode);
                }
            }
            this.currentStage = stage;
            this.subscenes.logo.getChildMeshes(true).forEach(m => {
                m.visibility && m.visibility > 0 && BABYLON.Animation.CreateAndStartAnimation(m.name + 'Off', m, 'visibility', 25, 37, 0.95, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
            });
            this.subscenes.dna.getChildMeshes(true).forEach(m => {
                m.visibility && m.visibility > 0 && BABYLON.Animation.CreateAndStartAnimation(m.name + 'Off', m, 'visibility', 25, 37, 0.95, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
            });
            this.subscenes.graph.getChildMeshes().forEach(m => {
                if (m.name == 'graphSphere') {
                    m.visibility && m.visibility > 0 && BABYLON.Animation.CreateAndStartAnimation(m.name + 'Off', m, 'visibility', 25, 37, 1.0, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                }
                if (m.name == 'graphLinesMesh') {
                    m.visibility && m.visibility > 0 && BABYLON.Animation.CreateAndStartAnimation(m.name + 'Off', m, 'visibility', 25, 37, 0.6, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                }
            });
            this.subscenes.fractal.visibility &&
                this.subscenes.fractal.visibility > 0 &&
            BABYLON.Animation.CreateAndStartAnimation(this.subscenes.fractal.name + 'Off', this.subscenes.fractal, 'visibility', 25, 37, 0.95, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
            this.subscenes.fractal.visibility &&
            this.subscenes.fractal.visibility > 0 &&
            BABYLON.Animation.CreateAndStartAnimation(this.subscenes.copula.name + 'Off', this.subscenes.copula, 'visibility', 25, 25, 0.01, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
            this.subscenes.copula.visibility &&
                this.subscenes.copula.visibility > 0 &&
                BABYLON.Animation.CreateAndStartAnimation(this.subscenes.copula.name + 'Off', this.subscenes.copula, 'visibility', 25, 37, 0.95, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
            this.subscenes.terrain.visibility &&
                this.subscenes.terrain.visibility > 0 &&
                BABYLON.Animation.CreateAndStartAnimation(this.subscenes.terrain.name + 'Off', this.subscenes.terrain, 'visibility', 25, 37, 0.95, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        }
    }

    public skipLightOnce() {
        this.skipLightup = true;
    }

    private toggleLights(on?: boolean, callback?: () => void, glowCube = true, skipLines = false) {
        if (glowCube) {
            this.environment.isLightOn = on === undefined ? !this.environment.isLightOn : on;

            if (this.environment.isLightOn) {
                this.environment.lights_on.setEnabled(true);
                (this.environment.lights_on.metadata.animations as BABYLON.AnimationGroup[]).forEach(ag => {
                    ag.onAnimationGroupEndObservable.addOnce(() => {
                        (this.environment.glowingCube.metadata.animations as BABYLON.AnimationGroup[]).forEach(ag => {
                            ag.onAnimationGroupEndObservable.addOnce(() => {
                                this.scene.onAfterRenderObservable.addOnce(() => {
                                    this.environment.cubePlanes.setEnabled(true);
                                    this.environment.cubePlanes.scaling = new BABYLON.Vector3(1.125, 1.125, 1.125);
                                    this.environment.gradientLines.setEnabled(true);
                                    // this.environment.facesCube.setEnabled(true);
                                    this.environment.cubeSparks.setEnabled(true);

                                    BABYLON.Animation.CreateAndStartAnimation('sphereOn', this.environment.terrainCircles.metadata.sphere, 'visibility', 25, this.skipLightup ? 1 : 200, 0, 1, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);

                                    this.environment.ground.getChildMeshes().forEach(m => {
                                        BABYLON.Animation.CreateAndStartAnimation('groundOn', m, 'visibility', 25, this.skipLightup ? 1 : 200, 0, 0.95, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                                    });
                                    this.environment.terrainSky.getChildMeshes().forEach(m => {
                                        BABYLON.Animation.CreateAndStartAnimation('TerrianOn', m, 'visibility', 25, this.skipLightup ? 1 : 200, 0, 0.75, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                                    });
                                    this.environment.terrainGround.getChildMeshes().forEach(m => {
                                        BABYLON.Animation.CreateAndStartAnimation('TerrianGOn', m, 'visibility', 25, this.skipLightup ? 1 : 200, 0, 0.5, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                                    });
                                    this.environment.terrainSpheres.getChildMeshes().forEach(m => m.isVisible = true);
                                });

                                this.environment.cubeA.setEnabled(true);
                                (this.environment.cubeA.metadata.animations as BABYLON.AnimationGroup[]).forEach(ag => {
                                    ag.onAnimationGroupEndObservable.addOnce(() => {
                                        this.environment.cubeA.setEnabled(false);
                                        callback && callback();
                                    });
                                    ag.start(false);
                                });

                                this.environment.glowingCube.metadata.levitation?.start(true);
                                this.skipLightup = false;
                            });
                            ag.start(false, 1, this.skipLightup ? 139 : 48, 140);
                        });
                    });
                    ag.start(false, 1, this.skipLightup || skipLines || !this.needLines ? ag.to * 0.85 : 0, ag.to * 0.85);
                    this.needLines = false;
                });
            } else {
                this.environment.ground.getChildMeshes().forEach(m => {
                    BABYLON.Animation.CreateAndStartAnimation('groundOff', m, 'visibility', 25, 100, 0.95, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                });
                this.environment.terrainSky.getChildMeshes().forEach(m => {
                    BABYLON.Animation.CreateAndStartAnimation('terrainOff', m, 'visibility', 25, 100, 0.75, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                });
                this.environment.terrainGround.getChildMeshes().forEach(m => {
                    BABYLON.Animation.CreateAndStartAnimation('terrainGOff', m, 'visibility', 25, 100, 0.5, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                });
                this.environment.terrainSpheres.getChildMeshes().forEach(m => m.isVisible = false);

                this.environment.lights_on.setEnabled(false);
                (this.environment.glowingCube.metadata.animations as BABYLON.AnimationGroup[]).forEach(ag => {
                    ag.onAnimationGroupEndObservable.addOnce(() => {
                        BABYLON.Animation.CreateAndStartAnimation('cubePlanesOffX', this.environment.cubePlanes, 'scaling.x', 25, 75, 1.125, 2, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                        BABYLON.Animation.CreateAndStartAnimation('cubePlanesOffY', this.environment.cubePlanes, 'scaling.y', 25, 75, 1.125, 2, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                        BABYLON.Animation.CreateAndStartAnimation('cubePlanesOffZ', this.environment.cubePlanes, 'scaling.z', 25, 75, 1.125, 2, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, undefined, () => {
                            this.environment.cubePlanes.setEnabled(false);
                            this.completeStage(this.currentStage);
                        });
                        this.environment.gradientLines.setEnabled(false);
                        // this.environment.facesCube.setEnabled(false);
                        this.environment.cubeSparks.setEnabled(false);
                        BABYLON.Animation.CreateAndStartAnimation('sphereOff', this.environment.terrainCircles.metadata.sphere, 'visibility', 25, 75, 0.95, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);

                        callback && callback();
                        this.environment.glowingCube.metadata.levitation?.stop();
                    });
                    ag.start(false, 1.25, 140, ag.to);
                });
                this.stopFlicker();
            }
        } else {
            if (on) {
                this.environment.terrainSky.getChildMeshes().forEach(m => {
                    BABYLON.Animation.CreateAndStartAnimation('TerrianOn', m, 'visibility', 25, 200, 0, 0.75, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                });
                this.environment.terrainGround.getChildMeshes().forEach(m => {
                    BABYLON.Animation.CreateAndStartAnimation('TerrianGOn', m, 'visibility', 25, 200, 0, 0.5, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                });
                this.environment.terrainSpheres.getChildMeshes().forEach(m => m.isVisible = true);

                if (this.environment.cubePlanes.isEnabled()) {
                    this.environment.ground.getChildMeshes().forEach(m => {
                        BABYLON.Animation.CreateAndStartAnimation('groundOff', m, 'visibility', 25, 100, 0.95, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                    });

                    this.environment.terrainSpheres.getChildMeshes().forEach(m => m.isVisible = false);

                    this.environment.lights_on.setEnabled(false);
                    (this.environment.glowingCube.metadata.animations as BABYLON.AnimationGroup[]).forEach(ag => {
                        ag.onAnimationGroupEndObservable.addOnce(() => {
                            this.environment.cubePlanes.setEnabled(false);
                            this.environment.cubeSparks.setEnabled(false);
                            this.environment.gradientLines.setEnabled(false);

                            BABYLON.Animation.CreateAndStartAnimation('sphereOff', this.environment.terrainCircles.metadata.sphere, 'visibility', 25, 25, 0.95, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);

                            callback && callback();
                            this.environment.glowingCube.metadata.levitation?.stop();
                        });
                        ag.start(false, 1.25, ag.to, ag.to);
                    });
                }

                this.stopFlicker();

                this.environment.isLightOn = false;

                callback && callback();
            } else {
                this.environment.terrainSky.getChildMeshes().forEach(m => {
                    BABYLON.Animation.CreateAndStartAnimation('terrainOff', m, 'visibility', 25, 200, 0.75, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                });
                this.environment.terrainGround.getChildMeshes().forEach(m => {
                    BABYLON.Animation.CreateAndStartAnimation('terrainGOff', m, 'visibility', 25, 200, 0.5, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
                });
                this.environment.terrainSpheres.getChildMeshes().forEach(m => m.isVisible = false);

                this.environment.isLightOn = false;

                this.stopFlicker();

                callback && callback();
            }
        }
    }

    private stopFlicker() {
        if (this.flicker) {
            const consumers = this.eventsConsumers.get(SceneEvent.FLICKER_OFF);
            consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.FLICKER_OFF }));
            this.flicker.stop();
            this.flicker = undefined;
            [this.environment.cubeB, this.environment.cubeC, this.environment.cubeD].forEach(m => m.setEnabled(false));
        }
    }

    public scroll(delta: number) {
        this.camera.radius *= (Math.pow(2, delta));
        if (this.camera.radius < 0) {
            this.camera.radius = 0.00025;
        }
    }

    /**
     * Consumes callback on event. Returns callback for removing consumer from the consumers list.
     * @param sceneEvent
     * @param callBack
     */
    consumeOnEvent = (sceneEvent: SceneEvent, callBack: SceneEventConsumerCallback) => {
        const newConsumer = new SceneEventConsumer(callBack);
        let consumers = this.eventsConsumers.get(sceneEvent);

        if (!consumers) {
            consumers = new SceneEventConsumers();
            this.eventsConsumers.set(sceneEvent, consumers);
        }

        consumers.consumersList.push(newConsumer);

        return () => {
            consumers.consumersList = consumers.consumersList.filter(consumer => consumer.id !== newConsumer.id);
        }
    }

    private completeStage(stage: SceneStage) {
        this.isStageReady = true;
        const consumers = this.eventsConsumers.get(SceneEvent.COMPLETED);
        consumers && consumers.consumersList.forEach(c => c.callback({ event: SceneEvent.COMPLETED, stage: stage }));
    }

    private async setupSubscenes() {
        this.subscenes.logo = this.generateLogo();
        this.subscenes.logo.position = new BABYLON.Vector3(0, 0, 0);
        this.subscenes.logo.scaling = new BABYLON.Vector3(1 / 3.5, 1 / 3.5, 1 / 3.5);
        this.subscenes.logo.setParent(this.environment.glowingCube);

        this.subscenes.terrain = this.generateTerrain(32, 1.5);
        this.subscenes.terrain.position = new BABYLON.Vector3(0, -0.5341994762420654, 0);
        this.subscenes.terrain.rotation = new BABYLON.Vector3(0, 1.4800321679939752, 0);
        this.subscenes.terrain.scaling = new BABYLON.Vector3(0.0625, 1, 0.0625);
        const terrainMaterial = new BABYLON.StandardMaterial("terrainMaterial");
        (terrainMaterial as BABYLON.StandardMaterial).disableLighting = true;
        terrainMaterial.wireframe = true;
        terrainMaterial.disableLighting = true;
        terrainMaterial.emissiveColor = new BABYLON.Color3(0.45, 0.45, 0.45);
        (terrainMaterial.pluginManager.getPlugin("OpaqueInside") as any).isEnabled = true;
        const boundings = {
            max: new BABYLON.Vector3(1, 1, 1),
            min: new BABYLON.Vector3(-1, -1, -1),
        };
        this.boundingPlugins.push((terrainMaterial.pluginManager.getPlugin("OpaqueInside") as any));
        (terrainMaterial.pluginManager.getPlugin("OpaqueInside") as any).uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
        // this.glow.referenceMeshToUseItsOwnMaterial(this.subscenes.terrain);
        this.subscenes.terrain.material = terrainMaterial;
        this.subscenes.terrain.setParent(this.environment.glowingCube);
        this.subscenes.terrain.position.y -= 0.4;

        this.subscenes.graph = this.generateGraph();
        this.subscenes.graph.position = new BABYLON.Vector3(-0.05, -0.125, 0);
        this.subscenes.graph.scaling = new BABYLON.Vector3(1.75, 1.75, 1.75);
        this.subscenes.graph.setParent(this.environment.glowingCube);

        this.subscenes.dna = this.generateDNA();
        this.subscenes.dna.position = new BABYLON.Vector3(-0.5361641049385071, -0.5750125050544739, -0.53288949280977249);
        this.subscenes.dna.rotation = new BABYLON.Vector3(0.00268636467363288, -1.6471061418724341, 0.860819838297319);
        this.subscenes.dna.scaling = new BABYLON.Vector3(0.500000077490662, 0.1933606185291151, 0.500000091865858);
        this.subscenes.dna.setParent(this.environment.glowingCube);

        this.subscenes.copula = this.generateCopula();
        this.subscenes.copula.position = new BABYLON.Vector3(0, 0.6652523875236511, 0);
        this.subscenes.copula.scaling = new BABYLON.Vector3(0.059124913378157715, 1.41899836063385, 0.059124913378157715);
        this.subscenes.copula.setParent(this.environment.glowingCube);

        const result = await this.generateFractal(fractal);
        this.subscenes.fractal = result;
        this.subscenes.fractal.position = new BABYLON.Vector3(0, 0, 0);
        this.subscenes.fractal.scaling = new BABYLON.Vector3(1 / 2.25, 1 / 2.25, 1 / 2.25);
        this.subscenes.fractal.setParent(this.environment.glowingCube);

        this.setupStages();
    }

    private initialize = (): void => {
        this.engine = new BABYLON.Engine(this.canvas, undefined, {
            useHighPrecisionFloats: false,
            useHighPrecisionMatrix: false
        });
        this.scene = new BABYLON.Scene(this.engine);
        this.camera = this.camera = new BABYLON.ArcRotateCamera("mainCamera", Math.PI / 2, Math.PI / 2, 10, BABYLON.Vector3.Zero(), this.scene);
        // this.camera.inputs.attached.pointers.pinchZoom = false;
        this.camera.fov = Math.PI / 4;
        this.camera.position = new BABYLON.Vector3(-0.2140316438002502, 0.6920735982630748, 5.9612687906831985);
        this.stagesManager = new StagesManager(this.canvas, this.engine, this.scene, this.environment, this.camera, stage => this.completeStage(stage));
        this.scene.clearColor = new BABYLON.Color4(0.00625, 0.00625, 0.00625, 1.0);
        this.scene.shadowsEnabled = false;
        this.scene.fogEnabled = true;
        this.scene.fogMode = BABYLON.Scene.FOGMODE_LINEAR;
        this.scene.fogStart = 35.0;
        this.scene.fogEnd = 60.0;
        this.scene.fogColor = new BABYLON.Color3(0.00625, 0.00625, 0.00625);
        this.glow = new BABYLON.GlowLayer("glow", this.scene, {
            mainTextureSamples: 4,
            blurKernelSize: 56,
        });
        // this.glow.intensity = 0.7799999999999999;
        this.glow.intensity = 0.8399999999999999;


        window.addEventListener('resize', () => {
            this.engine.resize();
        });

        BABYLON.RegisterMaterialPlugin("OpaqueInside", (material) => {
            (material as any).opaqueInside = new OpaqueInsidePluginMaterial(material);
            return (material as any).opaqueInside;
        });

        BABYLON.RegisterMaterialPlugin("ObscureInCenter", (material) => {
            (material as any).obscure = new ObscureInCenterPluginMaterial(material);
            return (material as any).obscure;
        });

        BABYLON.RegisterMaterialPlugin("Flickering", (material) => {
            (material as any).flickering = new FlickeringPluginMaterial(material);
            return (material as any).flickering;
        });

        BABYLON.RegisterMaterialPlugin("ShowAround", (material) => {
            (material as any).showAround = new ShowAroundPluginMaterial(material);
            return (material as any).showAround;
        });

        this.initializeCamera();
        this.initializeLight();
    }

    private generateLogo() {
        const result = new BABYLON.Mesh("logo");
        const biggerDiscs = [0, 13, 15, 37, 47];
        const sphere = BABYLON.MeshBuilder.CreateSphere('sphere', { segments: 8, diameter: 0.125 }, this.scene);
        sphere.setParent(result);
        sphere.visibility = 0;
        sphere.material = new BABYLON.StandardMaterial("logoMaterial", this.scene);
        (sphere.material.pluginManager.getPlugin("OpaqueInside") as any).isEnabled = true;
        const boundings = {
            max: new BABYLON.Vector3(1, 1, 1),
            min: new BABYLON.Vector3(-1, -1, -1),
        };
        this.boundingPlugins.push((sphere.material.pluginManager.getPlugin("OpaqueInside") as any));
        (sphere.material.pluginManager.getPlugin("OpaqueInside") as any).uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
        (sphere.material as BABYLON.StandardMaterial).emissiveColor = new BABYLON.Color3(1, 1, 1);
        (sphere.material as BABYLON.StandardMaterial).disableLighting = true;

        this.glow.referenceMeshToUseItsOwnMaterial(sphere);
        sphere.isVisible = false;
        for (let i = 0; i < 49 * 5; i++) {
            const instance = sphere.createInstance('logo' + i);
            let rescale = 1.5;
            if (biggerDiscs.includes(i % 49) && Math.floor(i / 49) === biggerDiscs.indexOf(i % 49)) {
                rescale = 4.5;
            }
            instance.scaling.multiplyInPlace(new BABYLON.Vector3(rescale, rescale, rescale));
            instance.setParent(sphere);
            instance.position = new BABYLON.Vector3(i % 7 - 3, Math.floor(i / 7) % 7 - 3, 7 / 5 * Math.floor(i / 49) - 14 / 5);
        }

        return result;
    }

    private generateTerrain(size = 128, height = 1, animated = false, options = { octaves: 10, persistence: 0.55 }) {
        const result = BABYLON.MeshBuilder.CreateGround("terrain", {
            width: size,
            height: size,
            subdivisions: size,
        }, this.scene);

        const noise = new BABYLON.NoiseProceduralTexture("perlin", size, this.scene);
        noise.octaves = options.octaves;
        noise.persistence = options.persistence;
        noise.animationSpeedFactor = animated ? 0 : 0;
        if (animated) {
            noise.onGeneratedObservable.add(texture => {
                if (Date.now() > LAST_GENERATION + 75) {
                    LAST_GENERATION = Date.now();
                    texture.readPixels().then(pixels => {
                        const vertexData = BABYLON.CreateGroundFromHeightMapVertexData({
                            subdivisions: size * 2,
                            height: size,
                            width: size,
                            buffer: pixels as Uint8Array,
                            bufferHeight: size,
                            bufferWidth: size,
                            minHeight: 0,
                            maxHeight: height,
                            colorFilter: new BABYLON.Color3(0.3, 0.59, 0.11),
                            alphaFilter: 0
                        });
                        vertexData.applyToMesh(result, true);
                        result._rebuild();
                    });
                }
            });
        } else {
            noise.onGeneratedObservable.addOnce(texture => {
                texture.readPixels().then(pixels => {
                    const vertexData = BABYLON.CreateGroundFromHeightMapVertexData({
                        subdivisions: size * 2,
                        height: size,
                        width: size,
                        buffer: pixels as Uint8Array,
                        bufferHeight: size,
                        bufferWidth: size,
                        minHeight: 0,
                        maxHeight: height,
                        colorFilter: new BABYLON.Color3(0.3, 0.59, 0.11),
                        alphaFilter: 0
                    });
                    vertexData.applyToMesh(result, true);
                    result._rebuild();
                });
            });
        }

        const groundMaterial = new GridMaterial("terrainMaterial", this.scene);
        groundMaterial.lineColor = new BABYLON.Color3(1.0, 1.0, 1.0);
        groundMaterial.mainColor = new BABYLON.Color3(0.0, 0.0, 0.0);
        groundMaterial.majorUnitFrequency = 1 / size;
        groundMaterial.minorUnitVisibility = 1;
        groundMaterial.fogEnabled = false;

        //groundMaterial.opacity = 0.98;
        result.material = groundMaterial;
        result.metadata = { noise: noise };
        result.visibility = 0;
        return result;
    }

    private generateGraph(generationCentresCount = 15, count = 600, lines = 400) {
        const result = new BABYLON.Mesh("graph");
        const sphere = BABYLON.MeshBuilder.CreateSphere('graphSphere', { segments: 8, diameter: 1 }, this.scene);
        sphere.setParent(result);
        sphere.visibility = 0;
        sphere.material = new BABYLON.StandardMaterial("graphMaterial", this.scene);
        (sphere.material as BABYLON.StandardMaterial).disableLighting = true;
        (sphere.material.pluginManager.getPlugin("OpaqueInside") as any).isEnabled = true;
        const boundings = {
            max: new BABYLON.Vector3(1, 1, 1),
            min: new BABYLON.Vector3(-1, -1, -1),
        };
        this.boundingPlugins.push((sphere.material.pluginManager.getPlugin("OpaqueInside") as any));
        (sphere.material.pluginManager.getPlugin("OpaqueInside") as any).uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
        (sphere.material as BABYLON.StandardMaterial).emissiveColor = new BABYLON.Color3(1, 1, 1);

        this.glow.referenceMeshToUseItsOwnMaterial(sphere);

        sphere.isVisible = false;

        const generationCentres: BABYLON.Vector3[] = [];
        for (let i = 0; i < generationCentresCount; i++) {
            generationCentres.push(new BABYLON.Vector3(Math.random() - 0.5, (Math.random() - 0.5) * 0.5, Math.random() - 0.5));
        }

        for (let i = 0; i < count; i++) {
            const instance = sphere.createInstance('graph-node' + i);
            const scale = Math.random() * 0.0125 + 0.0125;
            instance.scaling = new BABYLON.Vector3(scale, scale, scale);
            const offset = new BABYLON.Vector3(Math.random() * 0.125, Math.random() * 0.125, Math.random() * 0.125)
            instance.position = generationCentres[Math.floor(i / count * generationCentres.length)].add(offset);
            instance.setParent(sphere);
        }

        const positions: number[] = [];
        const indices: number[] = [];
        for (let i = 0; i < count; i++) {
            positions.push(sphere.instances[i].position.x, sphere.instances[i].position.y, sphere.instances[i].position.z);
        }

        for (let i = 0; i < lines; i++) {
            const from = Math.floor(Math.random() * count);
            if (Math.random() < 0.25) {
                const offset = 3;
                for (let j = (from - offset >= 0) ? from - offset : 0; j >= 0 && j < from + offset && j < count; j++) {
                    indices.push(from, j);
                }
            }

            const to = Math.floor(Math.random() * count);
            from >= 0 && from < count && to >= 0 && to < count && from !== to && indices.push(from, to);
        }

        const linesMesh = new BABYLON.Mesh("graphLinesMesh", this.scene);
        linesMesh.visibility = 0;
        let vertexData = new BABYLON.VertexData();
        vertexData.positions = [...positions];
        vertexData.indices = [...indices];
        vertexData.applyToMesh(linesMesh);

        linesMesh.material = sphere.material.clone("graphLinesMaterial");
        (linesMesh.material as BABYLON.StandardMaterial).disableLighting = true;
        (linesMesh.material as BABYLON.StandardMaterial).emissiveColor = new BABYLON.Color3(0.3, 0.3, 0.3);
        (linesMesh.material.pluginManager.getPlugin("OpaqueInside") as any).isEnabled = true;
        (linesMesh.material.pluginManager.getPlugin("OpaqueInside") as any).uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
        // this.glow.referenceMeshToUseItsOwnMaterial(linesMesh);
        linesMesh.material.fillMode = BABYLON.Material.LineListDrawMode
        linesMesh.setParent(result);

        return result;
    }

    private generateDNA(generationCentresCount = 30, count = 600, lines = 800) {
        const result = new BABYLON.Mesh("dna");
        const sphere = BABYLON.MeshBuilder.CreateSphere('dnaSphere', { segments: 8, diameter: 1 }, this.scene);
        sphere.setParent(result);
        sphere.visibility = 0;
        sphere.material = new BABYLON.StandardMaterial("dnaMaterial", this.scene);
        sphere.material.disableLighting = true;
        (sphere.material.pluginManager.getPlugin("OpaqueInside") as any).isEnabled = true;
        const boundings = {
            max: new BABYLON.Vector3(1, 1, 1),
            min: new BABYLON.Vector3(-1, -1, -1),
        };
        this.boundingPlugins.push((sphere.material.pluginManager.getPlugin("OpaqueInside") as any));
        (sphere.material.pluginManager.getPlugin("OpaqueInside") as any).uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
        (sphere.material as BABYLON.StandardMaterial).emissiveColor = new BABYLON.Color3(1, 1, 1);
        (sphere.material as BABYLON.StandardMaterial).disableLighting = true;

        this.glow.referenceMeshToUseItsOwnMaterial(sphere);

        sphere.isVisible = false;

        const generationCentres: BABYLON.Vector3[] = [];
        const generationCentres_revere: BABYLON.Vector3[] = [];
        for (let i = 0; i < generationCentresCount; i++) {
            const x = 4 * i / generationCentresCount;
            const y = Math.sin((i - generationCentresCount / 4.5) * 7 / generationCentresCount);
            const z = Math.sin(i * 3 / generationCentresCount) * 0.5;
            generationCentres.push(new BABYLON.Vector3(x - 0.5, y - 0.5, z - 0.5));
            generationCentres_revere.push(new BABYLON.Vector3(x - 0.5, -y - 0.5, -z - 0.5));
        }

        for (let i = 0; i < count / 2; i++) {
            const instance = sphere.createInstance('dna-node' + i);
            const scale = Math.random() * 0.0125 + 0.0125;

            const offset = new BABYLON.Vector3(Math.random() * 0.125, Math.random() * 0.125, Math.random() * 0.125);
            instance.scaling = new BABYLON.Vector3(scale, scale, scale);
            instance.position = generationCentres[Math.floor(i * 2 / count * generationCentres.length)].add(offset);

            instance.setParent(sphere);
        }

        for (let i = 0; i < count / 2; i++) {
            const instance = sphere.createInstance('dna-node-rev' + i);

            const offset = new BABYLON.Vector3(Math.random() * 0.125, Math.random() * 0.125, Math.random() * 0.125);
            instance.scaling = sphere.instances[i].scaling;
            instance.position = generationCentres_revere[Math.floor(i * 2 / count * generationCentres_revere.length)].add(offset);

            instance.setParent(sphere);
        }

        const positions: number[] = [];
        const indices: number[] = [];
        for (let i = 0; i < count; i++) {
            positions.push(sphere.instances[i].position.x, sphere.instances[i].position.y, sphere.instances[i].position.z);
        }
        for (let i = 0; i < count / 2; i++) {
            const instance = generationCentres[Math.floor(i * 2 / count * generationCentres.length)];
            const instance_rev = generationCentres_revere[Math.floor(i * 2 / count * generationCentres.length)];
            positions.push(
                (instance.x + instance_rev.x) / 2 + Math.random() * 0.05,
                (instance.y + instance_rev.y) / 2 + Math.random() * 0.05,
                (instance.z + instance_rev.z) / 2 + Math.random() * 0.05,
            );
        }

        for (let i = 0; i < count / 2; i++) {
            if (Math.random() < 0.75) {
                const offset = 10;
                for (let j = i + 1; j < (i + offset < count / 2 ? i + offset : count / 2); j++) {
                    indices.push(i, j);
                    indices.push(i + count / 2, j + count / 2);
                }
            }
            if (i % (count / generationCentresCount) <= (count / generationCentresCount / 3) && Math.random() < 0.75) {
                const from = i;
                const to = i + count / 2;
                if (from >= 0 && from < count && to >= 0 && to < count && from !== to) {
                    indices.push(from, from + count);
                    indices.push(from + count, to);
                }
            }
        }

        const linesMesh = new BABYLON.Mesh("dnaLinesMesh", this.scene);
        linesMesh.visibility = 0;
        let vertexData = new BABYLON.VertexData();
        vertexData.positions = [...positions];
        vertexData.indices = [...indices];
        vertexData.applyToMesh(linesMesh);

        linesMesh.material = sphere.material.clone("dnaLinesMaterial");
        (linesMesh.material as BABYLON.StandardMaterial).disableLighting = true;
        (linesMesh.material as BABYLON.StandardMaterial).emissiveColor = new BABYLON.Color3(1, 1, 1);
        linesMesh.material.fillMode = BABYLON.Material.LineListDrawMode;
        // (linesMesh.material.pluginManager.getPlugin("OpaqueInside") as any).isEnabled = true;
        // (linesMesh.material.pluginManager.getPlugin("OpaqueInside") as any).uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
        linesMesh.material.emissiveColor = new BABYLON.Color3(1, 1, 1);
        this.glow.referenceMeshToUseItsOwnMaterial(linesMesh);
        linesMesh.setParent(result);

        result.rotation = new BABYLON.Vector3(0, 0, Math.PI / 6);
        return result;
    }

    private generateCopula(size = 24) {
        const result = BABYLON.MeshBuilder.CreateGround("copula", {
            width: size,
            height: size,
            subdivisions: size,
        }, this.scene);
        result.visibility = 0;

        const texture = new BABYLON.Texture(copula, this.scene);
        texture.onLoadObservable.addOnce(texture => {
            texture.readPixels().then(pixels => {
                const vertexData = BABYLON.CreateGroundFromHeightMapVertexData({
                    subdivisions: size,
                    height: size,
                    width: size,
                    buffer: pixels as Uint8Array,
                    bufferHeight: 1500,
                    bufferWidth: 1500,
                    minHeight: 0,
                    maxHeight: 1,
                    colorFilter: new BABYLON.Color3(0.3, 0.59, 0.11),
                    alphaFilter: 0
                });
                vertexData.applyToMesh(result, true);
            });
        });

        const copulaMaterial = new BABYLON.StandardMaterial("copulaMaterial");
        copulaMaterial.disableLighting = true;
        copulaMaterial.emissiveColor = new BABYLON.Color3(0.6, 0.6, 0.6);
        copulaMaterial.wireframe = true;
        (copulaMaterial.pluginManager.getPlugin("OpaqueInside") as any).isEnabled = true;
        const boundings = {
            max: new BABYLON.Vector3(1, 1, 1),
            min: new BABYLON.Vector3(-1, -1, -1),
        };
        this.boundingPlugins.push((copulaMaterial.pluginManager.getPlugin("OpaqueInside") as any));
        (copulaMaterial.pluginManager.getPlugin("OpaqueInside") as any).uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
        result.material = copulaMaterial;
        // this.glow.referenceMeshToUseItsOwnMaterial(result);

        //groundMaterial.opacity = 0.98;
        result.rotate(new BABYLON.Vector3(0, 0, 1), Math.PI);
        return result;
    }

    private async generateFractal(url: string) {
        const finalArray: number[] = [];
        const result = await this.loader.loadSubscene(url)
        result.getChildMeshes().forEach(m => {
            const array = m.getVerticesData(BABYLON.VertexBuffer.PositionKind);
            const matrix = m.getWorldMatrix();
            if (array) {
                for (let i = 0; i < array.length; i += 3) {
                    const result = new BABYLON.Vector3();
                    BABYLON.Vector3.TransformCoordinatesFromFloatsToRef(array[i], array[i + 1], array[i + 2], matrix, result);
                    finalArray.push(result.x, result.y, result.z);
                }
            }
        });
        result.dispose();

        const mesh = new BABYLON.Mesh("fractal", this.scene);
        mesh.visibility = 0;
        const material = new BABYLON.StandardMaterial("fractalMaterial", this.scene);
        material.disableLighting = true;
        (material.pluginManager.getPlugin("OpaqueInside") as any).isEnabled = true;
        material.emissiveColor = new BABYLON.Color3(1.0, 1.0, 1.0);
        const boundings = {
            max: new BABYLON.Vector3(1, 1, 1),
            min: new BABYLON.Vector3(-1, -1, -1),
        };
        this.boundingPlugins.push((material.pluginManager.getPlugin("OpaqueInside") as any));
        (material.pluginManager.getPlugin("OpaqueInside") as any).uniformBoundings = boundings.max.subtract(boundings.min).scale(0.5);
        material.fillMode = BABYLON.Material.PointFillMode;
        material.pointSize = 2;
        this.glow.referenceMeshToUseItsOwnMaterial(mesh);
        mesh.material = material;

        const positions: number[] = [];
        const indices: number[] = [];
        let j = 0;
        for (let i = 0; i < finalArray.length; i += 3) {
            if (Math.random() > 0.5) {
                positions.push(finalArray[i], finalArray[i + 1], finalArray[i + 2]);
                indices.push(j);
                j++;
                for (let k = 0; k < Math.random() * 2; k++) {
                    positions.push(finalArray[i] + (Math.random() - 0.5) * 0.125, finalArray[i + 1] + (Math.random() - 0.5) * 0.125, finalArray[i + 2] + (Math.random() - 0.5) * 0.125);
                    indices.push(j);
                    j++;
                }
            }
        }

        let vertexData = new BABYLON.VertexData();
        vertexData.positions = [...positions];
        vertexData.indices = [...indices];
        vertexData.applyToMesh(mesh);

        const awayMesh = new BABYLON.Mesh("awayFract", this.scene);
        awayMesh.setEnabled(false);
        for (let i = 0; i < vertexData.positions.length; i++) {
            vertexData.positions[i] = (Math.random() - 0.5) * 10;
        }
        vertexData.applyToMesh(awayMesh);

        mesh.morphTargetManager = new BABYLON.MorphTargetManager();
        mesh.morphTargetManager.addTarget(BABYLON.MorphTarget.FromMesh(awayMesh, "away", 0));


        return mesh;
    }

    private initializeCamera = (): void => {
        this.camera.lowerRadiusLimit = 0.05;
        this.camera.lowerBetaLimit = Math.PI / 4;
        this.camera.upperBetaLimit = Math.PI / 1.75;
        this.camera.inputs.removeByType("ArcRotateCameraMouseWheelInput");
        this.camera.panningSensibility *= 0;
        this.camera.attachControl();
        this.scene.onPointerDown = () => {
            this.needsOffset = false;
            this.camera.lowerAlphaLimit = this.camera.alpha - Math.PI / 2;
            this.camera.upperAlphaLimit = this.camera.alpha + Math.PI / 2;
        }
        this.scene.onPointerUp = () => {
            this.needsOffset = true;
            this.stagesManager.resetCamera(this.mode);
            this.camera.lowerAlphaLimit = undefined;
            this.camera.upperAlphaLimit = undefined;
        }

        this.stagesManager.setCameraStage(SceneStage.LIGHTS_ON,
            {
                1: new BABYLON.Vector3(-0.2140316438002502, 0.6920735982630748, 5.9612687906831985),
                2: new BABYLON.Vector3(-0.2140316438002502, 0.6920735982630748, 5.9612687906831985),
                3: new BABYLON.Vector3(-0.2140316438002502, 0.6920735982630748, 5.9612687906831985),
                4: new BABYLON.Vector3(-0.2140316438002502, 0.6920735982630748, 5.9612687906831985),
            },
            {
                1: new BABYLON.Vector3(0, 0, 0),
                2: new BABYLON.Vector3(0, 0, 0),
                3: new BABYLON.Vector3(0, 0, 0),
                4: new BABYLON.Vector3(0, 0, 0),
            }
        );

        this.stagesManager.setCameraStage(SceneStage.LIGHTS_OFF,
            {
                1: new BABYLON.Vector3(-0.2140316438002502, 0.6920735982630748, 5.9612687906831985),
                2: new BABYLON.Vector3(-0.2140316438002502, 0.6920735982630748, 5.9612687906831985),
                3: new BABYLON.Vector3(-0.2140316438002502, 0.6920735982630748, 5.9612687906831985),
                4: new BABYLON.Vector3(-0.2140316438002502, 0.6920735982630748, 5.9612687906831985),
            },
            {
                1: new BABYLON.Vector3(0, 0, 0),
                2: new BABYLON.Vector3(0, 0, 0),
                3: new BABYLON.Vector3(0, 0, 0),
                4: new BABYLON.Vector3(0, 0, 0),
            }
        );

        const positions = {
            1: new BABYLON.Vector3(0.315877520220074, 0.864440949897317377, 6.183452154834339),
            2: new BABYLON.Vector3(-0.17307558469025325, 0.039334846339334456, 5.876329824592878),
            3: new BABYLON.Vector3(-2.21966181133518, 0.44642397932365663, 6.110997511428399),
            4: new BABYLON.Vector3(-1.59408706716092183, 0.4358444692605906, 6.000469225710511),
        }
        const targets = {
            1: new BABYLON.Vector3(-0.06551977674327439, -0.9740320015581135, 0.10030455543063753),
            2: new BABYLON.Vector3(0.37103808670767774, 0.2737708770206021, 0.8245825437182241),
            3: new BABYLON.Vector3(1.7969702166904824, 0.01634142870708709, 1.1586641089690972),
            4: new BABYLON.Vector3(1.2460076258552146, 0.006377138301320888, 1.042627812399353),
        }

        this.stagesManager.setCameraStage(SceneStage.LOGO,
            {
                1: new BABYLON.Vector3(positions[1].x, positions[1].y, positions[1].z),
                2: new BABYLON.Vector3(positions[2].x, positions[2].y, positions[2].z),
                3: new BABYLON.Vector3(positions[3].x, positions[3].y, positions[3].z),
                4: new BABYLON.Vector3(positions[4].x, positions[4].y, positions[4].z),
            },
            {
                1: new BABYLON.Vector3(targets[1].x, targets[1].y, targets[1].z),
                2: new BABYLON.Vector3(targets[2].x, targets[2].y, targets[2].z),
                3: new BABYLON.Vector3(targets[3].x, targets[3].y, targets[3].z),
                4: new BABYLON.Vector3(targets[4].x, targets[4].y, targets[4].z),
            }
        );
        this.stagesManager.setCameraStage(SceneStage.FRACTAL,
            {
                1: new BABYLON.Vector3(-positions[1].z, positions[1].y, positions[1].x),
                2: new BABYLON.Vector3(-positions[2].z, positions[2].y, positions[2].x),
                3: new BABYLON.Vector3(-positions[3].z, positions[3].y, positions[3].x),
                4: new BABYLON.Vector3(-positions[4].z, positions[4].y, positions[4].x),
            },
            {
                1: new BABYLON.Vector3(targets[1].x, targets[1].y, targets[1].z),
                2: new BABYLON.Vector3(targets[2].x, targets[2].y, targets[2].z),
                3: new BABYLON.Vector3(targets[3].x, targets[3].y, targets[3].z),
                4: new BABYLON.Vector3(targets[4].x, targets[4].y, targets[4].z),
            }
        );
        this.stagesManager.setCameraStage(SceneStage.GRAPH,
            {
                1: new BABYLON.Vector3(-positions[1].x, positions[1].y, -positions[1].z),
                2: new BABYLON.Vector3(-positions[2].x, positions[2].y, -positions[2].z),
                3: new BABYLON.Vector3(-positions[3].x, positions[3].y, -positions[3].z),
                4: new BABYLON.Vector3(-positions[4].x, positions[4].y, -positions[4].z),
            },
            {
                1: new BABYLON.Vector3(targets[1].x, targets[1].y, targets[1].z),
                2: new BABYLON.Vector3(targets[2].x, targets[2].y, targets[2].z),
                3: new BABYLON.Vector3(targets[3].x, targets[3].y, targets[3].z),
                4: new BABYLON.Vector3(targets[4].x, targets[4].y, targets[4].z),
            }
        );
        this.stagesManager.setCameraStage(SceneStage.DNA,
            {
                1: new BABYLON.Vector3(positions[1].z, positions[1].y, -positions[1].x),
                2: new BABYLON.Vector3(positions[2].z, positions[2].y, -positions[2].x),
                3: new BABYLON.Vector3(positions[3].z, positions[3].y, -positions[3].x),
                4: new BABYLON.Vector3(positions[4].z, positions[4].y, -positions[4].x),
            },
            {
                1: new BABYLON.Vector3(targets[1].x, targets[1].y, targets[1].z),
                2: new BABYLON.Vector3(targets[2].x, targets[2].y, targets[2].z),
                3: new BABYLON.Vector3(targets[3].x, targets[3].y, targets[3].z),
                4: new BABYLON.Vector3(targets[4].x, targets[4].y, targets[4].z),
            }
        );

        this.stagesManager.setCameraStage(SceneStage.COPULA,
            {
                1: new BABYLON.Vector3(-positions[1].z, positions[1].y, -positions[1].x),
                2: new BABYLON.Vector3(-positions[2].z, positions[2].y, -positions[2].x),
                3: new BABYLON.Vector3(-positions[3].z, positions[3].y, -positions[3].x),
                4: new BABYLON.Vector3(-positions[4].z, positions[4].y, -positions[4].x),
            },
            {
                1: new BABYLON.Vector3(-targets[1].x, targets[1].y, targets[1].z),
                2: new BABYLON.Vector3(-targets[2].x, targets[2].y, targets[2].z),
                3: new BABYLON.Vector3(-targets[3].x, targets[3].y, targets[3].z),
                4: new BABYLON.Vector3(-targets[4].x, targets[4].y, targets[4].z),
            }
        );
        this.stagesManager.setCameraStage(SceneStage.TERRAIN,
            {
                1: new BABYLON.Vector3(-positions[1].x, positions[1].y, positions[1].z),
                2: new BABYLON.Vector3(-positions[2].x, positions[2].y, positions[2].z),
                3: new BABYLON.Vector3(-positions[3].x, positions[3].y, positions[3].z),
                4: new BABYLON.Vector3(-positions[4].x, positions[4].y, positions[4].z),
            },
            {
                1: new BABYLON.Vector3(-targets[1].x, targets[1].y, targets[1].z),
                2: new BABYLON.Vector3(-targets[2].x, targets[2].y, targets[2].z),
                3: new BABYLON.Vector3(-targets[3].x, targets[3].y, targets[3].z),
                4: new BABYLON.Vector3(-targets[4].x, targets[4].y, targets[4].z),
            }
        );
    }

    private initializeLight() {
        this.light = new BABYLON.PointLight("light", new BABYLON.Vector3(0, 1, 0), this.scene);
    }

    private startLoop = (): void => {
        this.engine.runRenderLoop(() => {
            this.setMode();
            this.scene.render();
        });
    }
}

