import React from "react";
import "./RotateDisplay.less"


/**
 * Компонент для отображение на маленьких экранах с просьбой повернуть телефон
 */

export const RotateDisplay = () => (
    <div className="rotate-display">
        <img src='assets/images/rotate-device.webp' alt="rotate device" />
        <span>Please rotate your device...</span>
    </div>
);
