import { FIRST_LOADED_KEY } from "constants/state";
import { Howl } from "howler";

export class SoundService {
    buttonUnhover = new Howl({
        src: 'assets/sounds/button_unhover.mp3',
    });
    fullIntroAudio = new Howl({
        src: 'assets/sounds/Electric_cube(intro).mp3',
    });
    shortIntroAudio = new Howl({
        src: 'assets/sounds/short_intro.mp3',
    });
    mainAudio = new Howl({
        src: 'assets/sounds/main_loop.wav',
        loop: true,
        volume: .7
    });
    flickerAudio = new Howl({
        src: 'assets/sounds/Electric_cube(flicker).mp3',
        loop: true
    });
    clickNavigation = new Howl({
        src: 'assets/sounds/click_menu.mp3',
    });
    buttonHover = new Howl({
        src: 'assets/sounds/button_hover.mp3'
    });
    transitionEffect = new Howl({
        src: 'assets/sounds/transition.mp3'
    });

    transitionEffect2 = new Howl({
        src: 'assets/sounds/transition_2.mp3'
    });

    transitionEffect3 = new Howl({
        src: 'assets/sounds/transition_3.mp3'
    });

    transitionEffect4 = new Howl({
        src: 'assets/sounds/transition_4.mp3'
    });

    transitionEffectLast = new Howl({
        src: 'assets/sounds/transition_5.mp3'
    });
    interactionEffect = new Howl({
        src: 'assets/sounds/interaction_effect.mp3'
    });
    secondPageStart = new Howl({
        src: 'assets/sounds/second_page_display.mp3'
    });
    fromSecondToMain = new Howl({
        src: 'assets/sounds/return_to_main.mp3'
    });


    public get introAudio(): Howl {
        if (localStorage.getItem(FIRST_LOADED_KEY)) {
            return this.shortIntroAudio;
        }
        return this.fullIntroAudio;
    }


    mute() {
        this.introAudio.volume(0);
        this.fullIntroAudio.volume(0);
        this.mainAudio.volume(0);
        this.flickerAudio.volume(0);
        this.clickNavigation.volume(0);
        this.buttonUnhover.volume(0);
        this.buttonHover.volume(0);
        this.transitionEffect.volume(0);
        this.transitionEffect2.volume(0);
        this.transitionEffect3.volume(0);
        this.transitionEffect4.volume(0);
        this.transitionEffectLast.volume(0);
        this.interactionEffect.volume(0);
        this.secondPageStart.volume(0);
        this.fromSecondToMain.volume(0);
    }

    unmute() {
        this.introAudio.volume(1);
        this.mainAudio.volume(.7);
        this.flickerAudio.volume(1);
        this.clickNavigation.volume(1);
        this.buttonUnhover.volume(1);
        this.buttonHover.volume(1);
        this.transitionEffect.volume(1);
        this.transitionEffect2.volume(1);
        this.transitionEffect3.volume(1);
        this.transitionEffect4.volume(1);
        this.transitionEffectLast.volume(1);
        this.interactionEffect.volume(1);
        this.secondPageStart.volume(1);
        this.fromSecondToMain.volume(1);
    }
}
