import gsap from "gsap";
import { Navigation } from "components/shared/Navigation/Navigation";
import { TeamSlider } from "components/TeamSlider/TeamSlider";
import React, { useContext, useLayoutEffect } from "react";
import { GlobalAppContext } from "service/GlobalContext";
import "./Team.less";

export const Team: React.FC = () => {
    const { soundService: { interactionEffect } } = useContext(GlobalAppContext);

    const onMouseEnter = () => {
        interactionEffect.play()
    }

    const getAnimationConfigs = (delayRatio: number = 0): [any, any] => [
        {
            y: -100,
            opacity: 0,
        },
        {
            y: 0,
            opacity: 1,
            ease: "strong.inOut",
            delay: .7 + 0.2 * delayRatio,
            duration: .7,
        }
    ]
    useLayoutEffect(() => {
        gsap.fromTo(`.team .title.headling`, ...getAnimationConfigs())

        gsap.fromTo(`.team .team__text > :nth-child(2)`, ...getAnimationConfigs(1))

        gsap.fromTo(`.team .team__text > :nth-child(3)`, ...getAnimationConfigs(2))

        gsap.fromTo(`.team .team__text > :nth-child(4)`, ...getAnimationConfigs(3))
    }, [])

    return (
        <section className="team container">
            <h1 className="title team__title headling">Team <span>001</span></h1>
            <div className="team__content">
                <div className="team__text">
                    <h1 className="title headling">Team <span>001</span></h1>
                    <p className="paragraph">We’re a small and technical team with a common goal of succeeding and innovating in one of the world’s most difficult fields.</p>
                    <p className="paragraph">If you are an exceptional individual who shares our drive for building, innovating, and a startup environment then we’d love to hear from you.</p>
                    <a href="mailto::careers@corvusresearch.com" onMouseEnter={onMouseEnter} className="team__mail">careers@corvusresearch.com</a>
                </div>
                <TeamSlider />

            </div>
            <Navigation className="team__navigation" />
        </section>
    );
}
