import { makeAutoObservable } from "mobx";
import React from "react";
import { CanvasAppContext } from "./CanvasContext";
import { SoundService } from "./SoundService";


export class GlobalContext {

    isSceneLoaded: boolean = false;
    isAssetsLoaded: boolean = false;
    isContinueClicked: boolean = false;
    isAudioAvailable: boolean = true;
    soundService: SoundService;
    toggleAppAudio: () => void
    setSceneUnload: () => void

    constructor() {
        makeAutoObservable(this);
    }
}

export const GlobalAppContext: React.Context<GlobalContext> = React.createContext<GlobalContext>(null);
